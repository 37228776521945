import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { handle_error } from '../../contrib/utils'

import state from '../../contrib/state'
import history from '../../contrib/history'
import axios from '../../contrib/http'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    Popover,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'
import { User } from '../../contrib/models'

class Members extends Overview {
    state = observable({
        loading: false,
        actions_loading: {},
        members: [],
        email_filter: '',
        page: 1,
        count: 0,
    })

    load = async () => {
        this.state.loading = true
        let params = {}
        if (this.state.email_filter && this.state.email_filter.length >= 1) {
            params.email = this.state.email_filter
        }

        let results = await User.paginated_list(params, this.state.page)

        this.state.members = results.results
        this.state.count = results.count

        this.state.loading = false
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_search = () => {
        return (
            <div className="row">
                <div className="col col-xs-9">
                    <InputGroup
                        large={true}
                        placeholder="Search By Email"
                        value={this.state.email_filter}
                        onChange={(e) => {
                            this.state.email_filter = e.target.value
                        }}
                    />
                </div>
                <div className="col col-xs-3">
                    <Button
                        intent={Intent.SUCCESS}
                        large={true}
                        className="bp3-fill"
                        onClick={this.load}
                    >
                        Search
                    </Button>
                </div>
            </div>
        )
    }

    render_export = () => {
        return (
            <div>
                <Button
                    className="bp3-fill"
                    fill={false}
                    onClick={async () => {
                        var response = await axios.get('/users/export')
                        response = URL.createObjectURL(
                            new Blob([response.data], { type: 'text/csv' })
                        )

                        let a = document.createElement('a')
                        a.style = 'display: none'
                        a.href = response
                        a.download = 'users.csv'
                        a.click()
                        URL.revokeObjectURL(response)
                    }}
                >
                    Export User List
                </Button>
            </div>
        )
    }

    set_access = async (member, access) => {
        this.state.actions_loading[member.object_id] = true
        try {
            await member.set_access(access)
            member.has_access = access
            this.forceUpdate()
        } catch (e) {
            handle_error(e)
        }
        this.state.actions_loading[member.object_id] = false
    }

    set_solution_seeker = async (member, solution_seeker) => {
        this.state.actions_loading[member.object_id] = true
        try {
            await member.set_solution_seeker(solution_seeker)
            member.solution_seeker = solution_seeker
            this.forceUpdate()
        } catch (e) {
            handle_error(e)
        }
        this.state.actions_loading[member.object_id] = false
    }

    render_activation_action = (member) => {
        if (!member.has_access) {
            return (
                <Button
                    intent={Intent.SUCCESS}
                    onClick={() => this.set_access(member, true)}
                    loading={this.state.actions_loading[member.object_id]}
                >
                    Grant Access
                </Button>
            )
        }
        return (
            <Button
                intent={Intent.DANGER}
                onClick={() => this.set_access(member, false)}
                loading={this.state.actions_loading[member.object_id]}
            >
                Revoke Access
            </Button>
        )
    }

    render_solution_seeker_action = (member) => {
        if (!member.solution_seeker) {
            return (
                <Button
                    intent={Intent.SUCCESS}
                    onClick={() => this.set_solution_seeker(member, true)}
                    loading={this.state.actions_loading[member.object_id]}
                >
                    Grant Access
                </Button>
            )
        }
        return (
            <Button
                intent={Intent.DANGER}
                onClick={() => this.set_solution_seeker(member, false)}
                loading={this.state.actions_loading[member.object_id]}
            >
                Revoke Access
            </Button>
        )
    }

    render_member = (member) => {
        return (
            <tr>
                <td>
                    {member.first_name} {member.last_name}
                </td>
                <td>{member.email}</td>
                <td>{this.render_activation_action(member)}</td>
                <td>{this.render_solution_seeker_action(member)}</td>
                <td>
                    <Button onClick={() => history.push(member.client_path)}>
                        View Profile
                    </Button>
                </td>
            </tr>
        )
    }

    render_members = () => {
        if (this.state.loading) {
            return (
                <div>
                    <div className="small-gap" />
                    <NonIdealState icon={<Spinner />} title="Loading..." />
                    <div className="small-gap" />
                </div>
            )
        }

        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Wait-list Status</th>
                        <th>Bounty Posting Status</th>
                        <th>User Profile</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.members.map((member) =>
                        this.render_member(member)
                    )}
                </tbody>
            </table>
        )
    }

    render_pagination = () => {
        return <div></div>
    }

    render() {
        return (
            <Card>
                <div className="row">
                    <div className="col col-xs-6">
                        <h3>
                            Manage Users (
                            {
                                this.state.members.filter(
                                    (u) => u.solution_seeker
                                ).length
                            }{' '}
                            Corporations and{' '}
                            {
                                this.state.members.filter(
                                    (u) => !u.solution_seeker
                                ).length
                            }{' '}
                            Innovators)
                        </h3>
                        <p>
                            The following are all the registered users on
                            BountyBoard. Use the search filter to look for a
                            specific user
                        </p>
                    </div>
                    <div className="col col-xs-6">
                        <div className="end-xs">
                            {this.render_search()}
                            <div className="tiny-gap" />
                            {this.render_export()}
                        </div>
                    </div>
                </div>

                <div className="tiny-gap" />
                {this.render_members()}
                <div className="tiny-gap" />
                {this.render_pagination()}
            </Card>
        )
    }
}

export default observer(Members)
