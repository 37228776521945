import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import state from '../../contrib/state'
import { Auth } from 'aws-amplify'
import {
    Button,
    Divider,
    InputGroup,
    Intent,
    Position,
    Toaster,
    ButtonGroup,
} from '@blueprintjs/core'

import history from '../../contrib/history'

export default observer(
    class Login extends React.Component {
        state = observable({
            email: '',
            password: '',
            show_confirmation_field: false,
            confirm_code: '',
        })

        login = async (e) => {
            state.loading = true
            // stop default submit
            e.preventDefault()

            if (this.state.show_confirmation_field) {
                try {
                    await Auth.confirmSignUp(
                        this.state.email,
                        this.state.confirm_code
                    )
                } catch (e) {
                    Toaster.create({ position: Position.TOP }).show({
                        intent: Intent.DANGER,
                        message: e.message,
                    })
                }
            }

            try {
                await Auth.signIn({
                    username: this.state.email,
                    password: this.state.password,
                })
                // show message about operation being successful
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.SUCCESS,
                    message:
                        'Successfully authenticated. Please wait while you get redirected.',
                })

                let params = new URLSearchParams(window.location.search)

                if (params.get('redirect_uri')) {
                    window.location = params.get('redirect_uri')
                } else {
                    // reload the page
                    history.push('/')
                    window.location.reload()
                }
            } catch (e) {
                var message = e.message
                if (e.code === 'UserNotConfirmedException') {
                    message =
                        'Your account is not confirmed. Please enter the confirmation code that was emailed to you'
                    this.state.show_confirmation_field = true
                }
                // show message about operation being successful
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.DANGER,
                    message: message,
                })
            }
            state.loading = false
        }

        render_confirm_field = () => {
            if (!this.state.show_confirmation_field) return <div />
            return (
                <div>
                    <InputGroup
                        large={true}
                        leftIcon="lock"
                        placeholder="Your Confirmation Code"
                        onChange={(event) =>
                            (this.state.confirm_code = event.target.value)
                        }
                    />
                    <div className="tiny-gap" />
                </div>
            )
        }

        render() {
            return (
                <div className="row center-xs">
                    <div className="col-lg-4 col-md-6 col-sm-12 start-xs">
                        <div className="medium-gap" />
                        <div className="authentication-card card">
                            <img
                                src="/assets/logo.png"
                                className="logo-image"
                            />
                            <div className="tiny-gap" />

                            <p className="bp3-text-muted">
                                Login or sign up for a free account to
                                collaborate with other innovative minds and
                                create real lasting change.
                            </p>

                            <div className="tiny-gap" />
                            <form onSubmit={this.login}>
                                <InputGroup
                                    large={true}
                                    leftIcon="user"
                                    placeholder="Your Email Address"
                                    onChange={(event) =>
                                        (this.state.email = event.target.value)
                                    }
                                    type="email"
                                />
                                <div className="tiny-gap" />
                                <InputGroup
                                    large={true}
                                    leftIcon="lock"
                                    placeholder="Your Password"
                                    onChange={(event) =>
                                        (this.state.password =
                                            event.target.value)
                                    }
                                    type="password"
                                />
                                <div className="tiny-gap" />
                                {this.render_confirm_field()}

                                <div className="end-xs">
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={this.login}
                                        large={true}
                                        type="submit"
                                    >
                                        Login
                                    </Button>
                                    <div className="tiny-gap" />
                                    <Divider vertical={true} />
                                    <div className="tiny-gap" />
                                    <Button
                                        minimal={true}
                                        onClick={() =>
                                            history.push(
                                                '/users/actions/signup'
                                            )
                                        }
                                    >
                                        Don't have an account? Signup for a free
                                        account
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <div className="tiny-gap" />
                        <Button
                            onClick={() =>
                                history.push('/users/actions/forgot-password')
                            }
                            minimal={true}
                        >
                            Forgot Your Password?
                        </Button>
                    </div>
                </div>
            )
        }
    }
)
