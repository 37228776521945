import React from 'react'

import { User } from '../../contrib/models'
import Overview from '../../contrib/components/overview'

import SolutionSeeker from '../components/overview_solution_seeker'
import SolutionProvider from '../components/overview_solution_provider'

import { observer } from 'mobx-react'

class UserOverview extends Overview {
    model_class = User

    render_content = () => {
        if (this.model().solution_seeker)
            return <SolutionProvider model={this.model()} />
        return <SolutionProvider model={this.model()} />
    }
}

export default observer(UserOverview)
