import React from 'react'
import BountyCard from './card'

import { observer } from 'mobx-react'

class BountiesList extends React.Component {
    render() {
        return (
            <div>
                {this.props.items.map((i) => (
                    <BountyCard model={i} />
                ))}
            </div>
        )
    }
}

export default observer(BountiesList)
