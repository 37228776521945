import React from 'react'
import { NonIdealState, Button, Intent } from '@blueprintjs/core'

function try_again() {
    window.location.reload()
}

class Error404 extends React.Component {
    render() {
        return (
            <div className="content-view">
                <div className="row center-xs">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="medium-gap"></div>
                        <NonIdealState
                            icon="error"
                            title="Error 502"
                            description="This page could not be reached."
                            action={
                                <Button
                                    intent={Intent.PRIMARY}
                                    text="Try Again"
                                    onClick={try_again}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Error404
