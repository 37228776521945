import React from 'react'
import { observer } from 'mobx-react'

import { Callout, Intent } from '@blueprintjs/core'

export default observer(
    class PasswordCheck extends React.Component {
        has_special_characters = () => {
            for (let char in "~`!@#$%^&*()_-+={}[]:>;',\\</?*-+") {
                if (this.props.password.indexOf(char) !== -1) return true
            }
            return false
        }

        render_has_special_characters = () => {
            if (!this.has_special_characters()) {
                return (
                    <li>
                        Your password has to contain at least 1 special
                        character.
                    </li>
                )
            }
        }

        has_digits = () => {
            for (let char in '0123456789') {
                if (this.props.password.indexOf(char) !== -1) return true
            }
            return false
        }

        render_has_digits = () => {
            if (!this.has_digits()) {
                return <li>Your password has to contain at least 1 digit.</li>
            }
        }

        has_upper_case_letters = () => {
            return this.props.password.toUpperCase() !== this.props.password
        }

        render_has_upper_case_letters = () => {
            if (!this.has_upper_case_letters()) {
                return (
                    <li>
                        Your password has to contain at least one uppercase
                        letter
                    </li>
                )
            }
        }

        has_lower_case_letters = () => {
            return this.props.password.toLowerCase() !== this.props.password
        }

        render_has_lower_case_letters = () => {
            if (!this.has_lower_case_letters()) {
                return (
                    <li>
                        Your password has to contain at least one lowercase
                        letter
                    </li>
                )
            }
        }

        is_long_enough = () => {
            return this.props.password.length >= 8
        }

        render_is_long_enough = () => {
            if (!this.is_long_enough()) {
                return (
                    <li>Your password has to be at least 8 characters long</li>
                )
            }
        }

        is_good_password() {
            return (
                this.has_special_characters() &&
                this.has_digits() &&
                this.has_upper_case_letters() &&
                this.has_lower_case_letters() &&
                this.is_long_enough()
            )
        }

        render() {
            if (this.props.password.length === 0) return <div />
            if (this.is_good_password()) {
                return <Callout intent={Intent.SUCCESS}>Good password!</Callout>
            }
            return (
                <Callout intent={Intent.DANGER}>
                    {this.render_has_special_characters()}
                    {this.render_has_digits()}
                    {this.render_has_upper_case_letters()}
                    {this.render_has_lower_case_letters()}
                    {this.render_is_long_enough()}
                </Callout>
            )
        }
    }
)
