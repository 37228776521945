import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import state from '../state'
import history from '../history'

import { Auth } from 'aws-amplify'

import { Button, Navbar, Alignment } from '@blueprintjs/core'

export default observer(
    class Menu extends React.Component {
        actions_state = observable({
            obj: {},
        })

        managed_communities = () => {
            return state.communities.filter((c) => c.can_edit)
        }

        posted_bounties = () => {
            return state.bounties.filter((c) => c.can_edit)
        }

        available_actions = () => {
            var actions = []

            if (state.current_user.is_staff) {
                actions.push([
                    <Button
                        minimal={true}
                        icon="user"
                        large="true"
                        onClick={() => history.push('/admin/')}
                    >
                        Admin Dashboard
                    </Button>,
                    <Navbar.Divider />,
                ])
            }

            if (state.current_user.solution_seeker) {
                actions.push([
                    <Button
                        minimal={true}
                        icon="plus"
                        large="true"
                        onClick={() => history.push('/bounty/post')}
                    >
                        Post New Bounty
                    </Button>,
                ])
            }

            return actions
        }

        componentDidMount() {}

        render() {
            return (
                <div>
                    <Navbar className="bp3-dark fixed">
                        <div className="content-view">
                            <Navbar.Group align={Alignment.LEFT}>
                                <h2
                                    className="title"
                                    onClick={() => history.push('/')}
                                >
                                    BountyBoard
                                </h2>
                                <Navbar.Divider />
                                {this.available_actions()}
                            </Navbar.Group>

                            <Navbar.Group align={Alignment.RIGHT}>
                                <Button
                                    minimal={true}
                                    icon="user"
                                    large="true"
                                    onClick={() =>
                                        history.push(
                                            '/users/' +
                                                state.current_user.object_id
                                        )
                                    }
                                >
                                    My Profile
                                </Button>
                                <Navbar.Divider />
                                <Button
                                    minimal={true}
                                    icon="log-out"
                                    large="true"
                                    onClick={() => {
                                        Auth.signOut({ global: true })
                                            .then((data) =>
                                                window.location.reload()
                                            )
                                            .catch((err) => console.log(err))
                                    }}
                                >
                                    Logout
                                </Button>
                            </Navbar.Group>
                        </div>
                    </Navbar>
                </div>
            )
        }
    }
)
