import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { Bounty } from '../../contrib/models'
import BountyList from '../../bounty/components/list'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    NonIdealState,
} from '@blueprintjs/core'

import { Auth } from 'aws-amplify'

class Bounties extends React.Component {
    state = observable({
        models: [],
    })

    load = async () => {
        await Auth.currentSession()
        this.state.models = await Bounty.list({ posted: true })
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_empty_state = () => {
        return (
            <Card>
                <NonIdealState
                    icon="search"
                    title="No Bounties!"
                    description="You have not posted any bounties yet. To get started, please click on the button below."
                    action={
                        <Button
                            intent={Intent.PRIMARY}
                            onClick={() => history.push('/bounty/post/')}
                            large={true}
                        >
                            Post a New Bounty
                        </Button>
                    }
                />
            </Card>
        )
    }

    render() {
        var inner_render
        if (this.state.models.length === 0)
            inner_render = this.render_empty_state()
        else inner_render = <BountyList items={this.state.models} />

        return (
            <div>
                <h3>My Posted Bounties</h3>
                {inner_render}
            </div>
        )
    }
}

export default observer(Bounties)
