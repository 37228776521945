import React from 'react'
import moment from 'moment'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import history from '../../contrib/history'

import { Bounty } from '../../contrib/models'
import { handle_error } from '../../contrib/utils'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    Popover2,
    Divider,
    Navbar,
    Alignment,
    EditableText,
} from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'

import Editor from 'rich-markdown-editor'

class PostBounty extends React.Component {
    state = observable({
        loading: false,

        name: '',
        description: '',

        eligibility: '',
        budget: '',
        deadline: new Date(),

        content: '',
        temp_content: '',

        updated_at: '10 minutes ago',
        loaded_draft: true,
    })

    load = () => {
        let state = localStorage.getItem('BountyBoard::draft')
        if (!state) return

        let parsed = JSON.parse(state)
        this.state.name = parsed.name
        this.state.description = parsed.description
        this.state.eligibility = parsed.eligibility
        this.state.budget = parsed.eligibility
        this.state.deadline = new Date(parsed.deadline)
        this.state.content = parsed.content
        this.state.updated_at = parsed.updated_at

        this.state.loaded_draft = true
        this.forceUpdate()
    }

    is_bounty_complete = () => {
        return (
            this.state.name &&
            this.state.name.length >= 1 &&
            this.state.description &&
            this.state.description.length >= 1 &&
            this.state.content &&
            this.state.content.length >= 1 &&
            this.state.eligibility &&
            this.state.eligibility.length >= 1 &&
            this.state.budget &&
            this.state.budget.length >= 1 &&
            this.state.deadline
        )
    }

    save_state = () => {
        localStorage.setItem(
            'BountyBoard::draft',
            JSON.stringify({
                name: this.state.name,
                description: this.state.description,
                eligibility: this.state.eligibility,
                budget: this.state.budget,
                deadline: this.state.deadline,
                content: this.state.content,
                updated_at: new Date().getTime(),
            })
        )
    }

    discard_draft = () => {
        this.state.name = ''
        this.state.description = ''
        this.state.eligibility = ''
        this.state.budget = ''
        this.state.content = ''
        this.state.deadline = new Date()
        this.state.updated_at = moment(new Date()).fromNow()
        this.state.loaded_draft = false

        this.save_state()
        this.forceUpdate()
    }

    post_bounty = async () => {
        this.state.loading = true
        try {
            let obj = await Bounty.create({
                name: this.state.name,
                description: this.state.description,
                eligibility: this.state.eligibility,
                budget: this.state.budget,
                deadline: this.state.deadline.toISOString().split('T')[0],
                content: this.state.content,
            })
            // discard the current draft and push the user to the newly created bounty
            this.discard_draft()
            history.push(obj.client_path)
        } catch (e) {
            handle_error(e)
        }
        this.state.loading = false
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_draft_notice = () => {
        if (!this.state.loaded_draft) return <div />

        // only return this if a draft was loaded into the state
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                Current draft was last updated{' '}
                                <b>{moment(this.state.updated_at).fromNow()}</b>
                            </Navbar.Heading>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Button
                                large={true}
                                outlined={true}
                                intent={Intent.DANGER}
                                onClick={this.discard_draft}
                            >
                                Discard Draft
                            </Button>
                        </Navbar.Group>
                    </div>
                </Navbar>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.render_draft_notice()}
                <div className="content-view bp3-text-large bp3-running-text">
                    <h5 className="bp3-text-muted">Bounty Title</h5>
                    <p className="bp3-text-muted">
                        For a good title, we recommend using your organization's
                        name alongside the nature of the challenge you are
                        trying to solve. Some sample titles are: "Department of
                        Defense - Airfield Debris Removal" and "NHLBI - Air You
                        Wear Challenge".
                    </p>

                    <h2>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.state.name}
                            placeholder={'Please enter a title for your bounty'}
                            onChange={(v) => (this.state.name = v)}
                            onConfirm={this.save_state}
                        />
                    </h2>

                    <div className="small-gap" />
                    <Divider />
                    <h5 className="bp3-text-muted">Bounty Summary</h5>
                    <p className="bp3-text-muted">
                        Please try to briefly describe your bounty in a few
                        sentences. BountyBoard will use this brief summary when
                        promoting your bounty to the solution provider
                        communities.
                    </p>

                    <h4>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.state.description}
                            multiline={true}
                            placeholder={'Please briefly describe your bounty'}
                            onChange={(v) => (this.state.description = v)}
                            onConfirm={this.save_state}
                        />
                    </h4>

                    <div className="small-gap" />
                    <Divider />
                    <h5 className="bp3-text-muted">Bounty Eligibility</h5>
                    <p className="bp3-text-muted">
                        Who is eligible to apply to your Bounty? These
                        requirements will be shown to the solution providers
                        before they submit a proposal to ensure they meet your
                        eligibility criteria.
                    </p>
                    <div className="tiny-gap" />

                    <p>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.state.eligibility}
                            multiline={true}
                            placeholder={
                                'Who is eligible to submit a proposal?'
                            }
                            onChange={(v) => (this.state.eligibility = v)}
                            onConfirm={this.save_state}
                        />
                    </p>

                    <div className="small-gap" />
                    <Divider />
                    <h5 className="bp3-text-muted">Bounty Budget</h5>
                    <p className="bp3-text-muted">
                        What is the budget for your Bounty? This will help
                        ensure the submitted proposals will match your bounty's
                        budget.
                    </p>
                    <div className="tiny-gap" />

                    <p>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.state.budget}
                            multiline={true}
                            placeholder={'What is the budget for this bounty?'}
                            onChange={(v) => (this.state.budget = v)}
                            onConfirm={this.save_state}
                        />
                    </p>

                    <div className="small-gap" />
                    <Divider />
                    <h5 className="bp3-text-muted">
                        Bounty Submissions Deadline
                    </h5>
                    <p className="bp3-text-muted">
                        The deadline until which new proposals can be submitted.
                        You will be able to change this date later as well.
                    </p>
                    <div className="tiny-gap" />
                    <DatePicker
                        value={this.state.deadline}
                        highlightCurrentDay={true}
                        onChange={(date) => {
                            this.state.deadline = date
                            this.save_state()
                        }}
                    />

                    <div className="small-gap" />
                    <Divider />

                    <h5 className="bp3-text-muted">Bounty Writeup</h5>
                    <p className="bp3-text-muted">
                        Please provide a detailed write up of the challenge you
                        are trying to solve and the outcomes you are looking
                        for.
                    </p>
                    <div className="tiny-gap" />

                    <Card>
                        <div className="content">
                            <Editor
                                value={this.state.content}
                                readOnly={false}
                                onChange={(v) => {
                                    this.state.temp_content = v()
                                }}
                                onBlur={() => {
                                    this.state.content = this.state.temp_content
                                    this.save_state()
                                }}
                            />
                        </div>
                    </Card>

                    <div className="small-gap" />

                    <div className="end-xs">
                        <Button
                            loading={this.state.loading}
                            large={true}
                            intent={Intent.SUCCESS}
                            disabled={!this.is_bounty_complete()}
                            onClick={this.post_bounty}
                        >
                            Submit Bounty
                        </Button>
                    </div>

                    <div className="small-gap" />
                </div>
            </div>
        )
    }
}

export default observer(PostBounty)
