import React from 'react'

import { BaseModel } from '../models'
import { handle_error } from '../utils'

import { NonIdealState, Spinner } from '@blueprintjs/core'

import { observable } from 'mobx'
import { Auth } from 'aws-amplify'

class Overview extends React.Component {
    ui = observable({})
    _state = observable({
        loading: true,
        not_found: false,
        model: {},
    })

    // must be implemented by subclass
    model_class = BaseModel

    model() {
        if (this.props.model) return this.props.model
        return this._state.model
    }

    save_model_attribute(attr) {
        return async (value) => {
            this.model()[attr] = value.target.value
            try {
                await this.model().save([attr])
                this.forceUpdate()
            } catch (e) {
                handle_error(e)
            }
        }
    }

    update_model_attribute(attr) {
        return (value) => {
            this.model()[attr] = value.target.value
            this.forceUpdate()
        }
    }

    model_updated = async () => {
        this._state.loading = true
        try {
            let obj = new this.model_class({
                object_id: this.props.match.params.object_id,
            })
            await obj.sync()
            this._state.model = obj
        } catch (err) {
            this._state.not_found = true
        }
        this._state.loading = false
        this.after_model_updated()
    }

    after_model_updated = async () => {
        // let subclasses extend this
    }

    componentDidMount() {
        Auth.currentSession().then(() => {
            this.model_updated()
        })
    }

    componentDidUpdate(prevProps, pervState, snapshot) {
        if (
            this.props.match &&
            this.props.match.params.object_id !==
                prevProps.match.params.object_id
        )
            this.model_updated()
    }

    // must be implemented by subclass
    render_content = () => {
        return <div />
    }

    render_loading = () => {
        return (
            <div className="content-view">
                <div className="small-gap" />
                <NonIdealState icon={<Spinner />} title="Loading..." />
            </div>
        )
    }

    render_not_found = () => {
        return (
            <div className="content-view">
                <div className="small-gap" />
                <NonIdealState
                    icon="search"
                    title="Not found!"
                    description="We couldn't find the link you are looking for"
                />
            </div>
        )
    }

    // must be implemented by subclass
    render() {
        if (this._state.loading) return this.render_loading()
        if (this._state.not_found) return this.render_not_found()
        return this.render_content()
    }
}

export default Overview
