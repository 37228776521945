import { Toaster, Position, Intent } from '@blueprintjs/core'

let error_toaster = Toaster.create({
    position: Position.TOP,
})

function handle_error(err) {
    function _pub_error(title, text) {
        error_toaster.show({
            message: title + ': ' + text,
            intent: Intent.WARNING,
        })
    }

    if (err.response) {
        if (err.response.status === 500) return
        for (let attr_index in err.response.data) {
            if (Array.isArray(err.response.data[attr_index])) {
                for (let current_err in err.response.data[attr_index]) {
                    _pub_error(
                        attr_index,
                        err.response.data[attr_index][current_err]
                    )
                }
            } else {
                _pub_error(attr_index, err.response.data[attr_index])
            }
        }
    }
}

let adjectives = [
    'adamant',
    'adroit',
    'amatory',
    'animistic',
    'antic',
    'arcadian',
    'baleful',
    'bellicose',
    'bilious',
    'boorish',
    'calamitous',
    'caustic',
    'cerulean',
    'comely',
    'concomitant',
    'contumacious',
    'corpulent',
    'crapulous',
    'defamatory',
    'didactic',
    'dilatory',
    'dowdy',
    'efficacious',
    'effulgent',
    'egregious',
    'endemic',
    'equanimous',
    'execrable',
    'fastidious',
    'feckless',
    'fecund',
    'friable',
    'fulsome',
    'garrulous',
    'guileless',
    'gustatory',
    'heuristic',
    'histrionic',
    'hubristic',
    'incendiary',
    'insidious',
    'insolent',
    'intransigent',
    'inveterate',
    'invidious',
    'irksome',
    'jejune',
    'jocular',
    'judicious',
    'lachrymose',
    'limpid',
    'loquacious',
    'luminous',
    'mannered',
    'mendacious',
    'meretricious',
    'minatory',
    'mordant',
    'munificent',
    'nefarious',
    'noxious',
    'obtuse',
    'parsimonious',
    'pendulous',
    'pernicious',
    'pervasive',
    'petulant',
    'platitudinous',
    'precipitate',
    'propitious',
    'puckish',
    'querulous',
    'quiescent',
    'rebarbative',
    'recalcitant',
    'redolent',
    'rhadamanthine',
    'risible',
    'ruminative',
    'sagacious',
    'salubrious',
    'sartorial',
    'sclerotic',
    'serpentine',
    'spasmodic',
    'strident',
    'taciturn',
    'tenacious',
    'tremulous',
    'trenchant',
    'turbulent',
    'turgid',
    'ubiquitous',
    'uxorious',
    'verdant',
    'voluble',
    'voracious',
    'wheedling',
    'withering',
    'zealous',
]
let nouns = [
    'ninja',
    'chair',
    'pancake',
    'statue',
    'unicorn',
    'rainbows',
    'laser',
    'senor',
    'bunny',
    'captain',
    'nibblets',
    'cupcake',
    'carrot',
    'gnomes',
    'glitter',
    'potato',
    'salad',
    'toejam',
    'curtains',
    'beets',
    'toilet',
    'exorcism',
    'stick figures',
    'mermaid eggs',
    'sea barnacles',
    'dragons',
    'jellybeans',
    'snakes',
    'dolls',
    'bushes',
    'cookies',
    'apples',
    'ice cream',
    'ukulele',
    'kazoo',
    'banjo',
    'opera singer',
    'circus',
    'trampoline',
    'carousel',
    'carnival',
    'locomotive',
    'hot air balloon',
    'praying mantis',
    'animator',
    'artisan',
    'artist',
    'colorist',
    'inker',
    'coppersmith',
    'director',
    'designer',
    'flatter',
    'stylist',
    'leadman',
    'limner',
    'make-up artist',
    'model',
    'musician',
    'penciller',
    'producer',
    'scenographer',
    'set decorator',
    'silversmith',
    'teacher',
    'auto mechanic',
    'beader',
    'bobbin boy',
    'clerk of the chapel',
    'filling station attendant',
    'foreman',
    'maintenance engineering',
    'mechanic',
    'miller',
    'moldmaker',
    'panel beater',
    'patternmaker',
    'plant operator',
    'plumber',
    'sawfiler',
    'shop foreman',
    'soaper',
    'stationary engineer',
    'wheelwright',
    'woodworkers',
]

let tags = [
    'Advertising',
    'AgTech',
    'AI',
    'Analysis',
    'AR/VR',
    'AudioTech',
    'BioTech',
    'BlockChain',
    'Chemicals',
    'CleanTech/Environment ',
    'Cloud Infrastructure',
    'ConstructionTech',
    'Consumer Health',
    'Consumer Internet',
    'Cosmetics',
    'Cryptocurrency',
    'Cybersecurity',
    'Data Services',
    'Developer Tools',
    'Diagnostics',
    'Digital Health',
    'Direct-to-Consumer (DTC)',
    'Drug Delivery',
    'E-commerce',
    'Education',
    'EnergyTech',
    'Enterprise Applications',
    'Enterprise Infrastructure',
    'Enterprise',
    'Entertainment & Sports',
    'Fashion',
    'FinTech',
    'Food and Beverage',
    'Future of Work',
    'Games',
    'Gaming/eSports',
    'Gig Economy',
    'GovTech',
    'Hardware',
    'Health & Hospital Services',
    'Health IT',
    'Human Capital/HRTech',
    'Impact',
    'Insurance',
    'IoT',
    'LegalTech',
    'Local Services',
    'Lodging/Hospitality',
    'Logistics',
    'Manufacturing',
    'MarketingTech',
    'Marketplaces',
    'Material Science',
    'Media/Content',
    'Medical Devices',
    'Messaging',
    'Mobility',
    'Parenting/Families',
    'Payments',
    'Pharmaceuticals',
    'Real Estate/PropTech',
    'Retail',
    'Robotics',
    'SaaS',
    'Sales & CRM',
    'Security',
    'Semiconductors',
    'SMB Software',
    'Social Commerce',
    'Social Networks',
    'Space',
    'TransportationTech',
    'Travel',
    'Wellness & Fitness',
]

function random_name() {
    return (
        adjectives[Math.floor(Math.random() * adjectives.length)] +
        ' ' +
        nouns[Math.floor(Math.random() * nouns.length)]
    )
}

export { handle_error, random_name, tags }
