import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { handle_error } from '../../contrib/utils'

import state from '../../contrib/state'
import history from '../../contrib/history'

import { Button, InputGroup, Intent, Card, Popover } from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'

class Invitations extends Overview {
    state = observable({
        invites: [],
    })

    load = async () => {
        this.state.invites = await this.model().get_invitations()
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    generate_invitation = async () => {
        try {
            await this.model().generate_invitation()
            await this.load()
        } catch (e) {
            handle_error(e)
        }
    }

    delete_invite = async (invite) => {
        try {
            await invite.destroy()
            await this.load()
        } catch (e) {
            handle_error(e)
        }
    }

    render_invitation = (item) => {
        item.last_used = undefined
        return (
            <tr>
                <td>{item.object_id}</td>
                <td>{item.last_used}</td>
                <td>{item.times_used}</td>
                <td>
                    <Popover>
                        <Button intent={Intent.DANGER}>Delete</Button>
                        <div style={{ width: '300px', padding: '16px' }}>
                            <p>
                                Are you sure you want to delete this invite
                                code?
                            </p>
                            <div className="tiny-gap" />
                            <div className="end-xs">
                                <Button
                                    intent={Intent.DANGER}
                                    onClick={() => this.delete_invite(item)}
                                >
                                    I am sure
                                </Button>
                            </div>
                        </div>
                    </Popover>
                </td>
            </tr>
        )
    }

    render_invitations = () => {
        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Last Used</th>
                        <th>Times Used</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.invites.map((item) =>
                        this.render_invitation(item)
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <Card>
                <div className="row">
                    <div className="col col-xs-6">
                        <h3>Community Invitation Codes</h3>
                        <p>
                            Manage your community's invitation codes. These
                            codes can be given to new members that you want to
                            invite to your community.
                        </p>
                    </div>
                    <div className="col col-xs-6">
                        <div className="end-xs">
                            <div className="tiny-gap" />
                            <div className="tiny-gap" />
                            <Button
                                large={true}
                                intent={Intent.PRIMARY}
                                onClick={this.generate_invitation}
                            >
                                Generate New Invitation
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="tiny-gap" />
                {this.render_invitations()}
            </Card>
        )
    }
}

export default observer(Invitations)
