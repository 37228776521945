import React from 'react'

import state from '../../contrib/state'
import axios from '../../contrib/http'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { Button, InputGroup, Intent, Card } from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'
import { handle_error } from '../../contrib/utils'

class OverviewHeader extends Overview {
    render() {
        return (
            <Card>
                <h3>Slack Integration</h3>
                <p>
                    Please enter the webhook URL of the slack channel you want
                    Bounty to post updates to here.
                </p>

                <div className="tiny-gap" />
                <InputGroup
                    large={true}
                    placeholder="Your Slack Webhook URL"
                    value={this.model().slack_webhook}
                    onChange={this.save_model_attribute('slack_webhook')}
                />

                <div className="tiny-gap" />
                <div className="end-xs">
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={async () => {
                            await axios.post(this.model().api_path + 'slack/')
                        }}
                        large={true}
                        type="submit"
                    >
                        Test the Integration
                    </Button>
                </div>
            </Card>
        )
    }
}

export default observer(OverviewHeader)
