import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { EditableText, Navbar, Alignment } from '@blueprintjs/core'

import state from '../../contrib/state'
import { handle_error } from '../../contrib/utils'

class OverviewHeader extends React.Component {
    state = observable({})

    model() {
        return this.props.model
    }

    save_model_attribute(attr) {
        return async (value) => {
            this.model()[attr] = value
            try {
                await this.model().save([attr])
                this.forceUpdate()
            } catch (e) {
                handle_error(e)
            }
        }
    }

    update_model_attribute(attr) {
        return (value) => {
            this.model()[attr] = value
            this.forceUpdate()
        }
    }

    render() {
        return (
            <div>
                <Navbar className="fixed">
                    <Navbar.Group align={Alignment.LEFT}>
                        <h2>
                            <EditableText
                                selectAllOnFocus={true}
                                multiline={true}
                                value={this.model().name}
                                onChange={this.update_model_attribute('name')}
                                onConfirm={this.save_model_attribute('name')}
                            />
                        </h2>
                    </Navbar.Group>
                    <Navbar.Group align={Alignment.RIGHT}></Navbar.Group>
                </Navbar>
                <div className="tiny-gap" />
                <div className="tiny-gap" />
                <div className="tiny-gap" />
                <div className="content">
                    <p className="enlarged-text">
                        <EditableText
                            selectAllOnFocus={true}
                            multiline={true}
                            value={this.model().description}
                            onChange={this.update_model_attribute(
                                'description'
                            )}
                            onConfirm={this.save_model_attribute('description')}
                        />
                    </p>
                </div>
            </div>
        )
    }
}

export default observer(OverviewHeader)
