import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import state from '../../contrib/state'
import { tags } from '../../contrib/utils'

import {
    Alignment,
    Divider,
    EditableText,
    Intent,
    Card,
    InputGroup,
    Checkbox,
    TextArea,
    Navbar,
    Callout,
} from '@blueprintjs/core'

import { handle_error } from '../../contrib/utils'

class Overview extends React.Component {
    save_model_attribute(attr) {
        return async (value) => {
            this.props.model[attr] = value.target.value
            try {
                await this.props.model.save([attr])
                this.forceUpdate()
            } catch (e) {
                handle_error(e)
            }
        }
    }

    update_model_attribute(attr) {
        return (value) => {
            this.props.model[attr] = value.target.value
            this.forceUpdate()
        }
    }

    render_tags_selector = () => {
        return (
            <div className="row">
                {tags.map((t) => {
                    return (
                        <div className="col col-xs-6">
                            <Checkbox
                                checked={
                                    this.props.model.tags.indexOf(t) !== -1
                                }
                                label={t}
                                onChange={(event) => {
                                    if (
                                        this.props.model.tags.indexOf(t) === -1
                                    ) {
                                        this.props.model.tags.push(t)
                                    } else {
                                        this.props.model.tags = this.props.model.tags.filter(
                                            (t2) => t2 !== t
                                        )
                                    }
                                    this.props.model.save(['tags'])
                                    this.forceUpdate()
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    render_no_access_alert = () => {
        if (!this.props.model.has_access) {
            return (
                <div className="content-view">
                    <div className="tiny-gap" />
                    <Card>
                        <Callout intent={Intent.WARNING}>
                            Welcome to BountyBoard. You are currently placed on
                            the waiting list and you will receive an email as
                            soon as a spot opens up! In the meantime, please
                            consider filling out your profile below to expedite
                            your access to BountyBoard.
                        </Callout>
                    </Card>
                </div>
            )
        }
    }

    render_static = () => {
        return (
            <div>
                <div className="content-view bp3-text-large bp3-running-text">
                    <h1>{this.props.model.company_name}</h1>
                    <h3>{this.props.model.company_description}</h3>

                    <div className="tiny-gap" />

                    <div className="row">
                        <div className="col col-xs-6">
                            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                                <tbody>
                                    <tr>
                                        <td>Website</td>
                                        <td>
                                            {this.props.model.company_website}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>LinkedIn Profile</td>
                                        <td>
                                            {this.props.model.company_linkedin}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Twitter Profile</td>
                                        <td>
                                            {this.props.model.company_twitter}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col col-xs-6">
                            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                                <tbody>
                                    <tr>
                                        <td>Employee Count</td>
                                        <td>
                                            {
                                                this.props.model
                                                    .company_employee_count
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Company Stage</td>
                                        <td>
                                            {this.props.model.company_stage}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Product Stage</td>
                                        <td>
                                            {this.props.model.product_stage}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col col-xs-6">
                            <h5>Procurement Process</h5>
                            <p>
                                {this.props.model.company_procurement_process}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render_editable = () => {
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                Changes are automatically saved. Last updated{' '}
                                {this.props.model.updated_at}
                            </Navbar.Heading>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}></Navbar.Group>
                    </div>
                </Navbar>
                {this.render_no_access_alert()}
                <div className="content-view bp3-running-text">
                    <div className="tiny-gap" />
                    <div className="row">
                        <div className="col col-xs-6">
                            <Card>
                                <p>Do you manage a community of Innovators?</p>
                                <Checkbox
                                    checked={this.props.model.community_manager}
                                    label="Community Manager"
                                    large={true}
                                    onChange={(event) => {
                                        if (
                                            this.props.model
                                                .community_manager === true
                                        ) {
                                            this.props.model.community_manager = false
                                        } else {
                                            this.props.model.community_manager = true
                                        }
                                        this.props.model.save([
                                            'community_manager',
                                        ])
                                        this.forceUpdate()
                                    }}
                                />
                                <div className="tiny-gap" />

                                <p>
                                    How many members does your community have?
                                </p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.community_members}
                                    placeholder="Community Members"
                                    onChange={this.update_model_attribute(
                                        'community_members'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'community_members'
                                    )}
                                />
                                <div className="tiny-gap" />
                            </Card>
                            <div className="tiny-gap" />

                            <Card>
                                <p>Company Tags</p>
                                <p>
                                    Picking company tags allows BountyBoard's
                                    matching algorithm to find better
                                    opportunities for you.
                                </p>
                                <div className="tiny-gap" />
                                {this.render_tags_selector()}
                            </Card>
                            <div className="tiny-gap" />
                        </div>
                        <div className="col col-xs-6">
                            <Card>
                                <p>First Name</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.first_name}
                                    placeholder="Your First Name"
                                    onChange={this.update_model_attribute(
                                        'first_name'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'first_name'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Last Name</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.last_name}
                                    placeholder="Your Last Name"
                                    onChange={this.update_model_attribute(
                                        'last_name'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'last_name'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Email Address</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.email}
                                    placeholder="Your Email"
                                    disabled={true}
                                />
                                <div className="tiny-gap" />

                                <p>LinkedIn Profile</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.linkedin}
                                    placeholder="Your LinkedIn Profile"
                                    onChange={this.update_model_attribute(
                                        'linkedin'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'linkedin'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Geographical Location</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.location}
                                    placeholder="Your Location (i.e. Ontario, Canada)"
                                    onChange={this.update_model_attribute(
                                        'location'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'location'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Skills</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.skills}
                                    placeholder="Your Primary Skills (i.e. Fullstack Developer)"
                                    onChange={this.update_model_attribute(
                                        'skills'
                                    )}
                                    onBlur={this.save_model_attribute('skills')}
                                />
                                <div className="tiny-gap" />
                            </Card>

                            <div className="tiny-gap" />
                            <Card>
                                <p>Company Name</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_name}
                                    placeholder="Your Company Name"
                                    onChange={this.update_model_attribute(
                                        'company_name'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_name'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company Description</p>
                                <div className="very-tiny-gap" />
                                <TextArea
                                    large={true}
                                    value={this.props.model.company_description}
                                    placeholder="Your Company Description"
                                    onChange={this.update_model_attribute(
                                        'company_description'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_description'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company Website</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_website}
                                    placeholder="Your Company Website"
                                    onChange={this.update_model_attribute(
                                        'company_website'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_website'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company LinkedIn Profile</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_linkedin}
                                    placeholder="Your Company LinkedIn"
                                    onChange={this.update_model_attribute(
                                        'company_linkedin'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_linkedin'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company Twitter Profile</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_twitter}
                                    placeholder="Your Company Twitter"
                                    onChange={this.update_model_attribute(
                                        'company_twitter'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_twitter'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company Employee Count</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={
                                        this.props.model.company_employee_count
                                    }
                                    placeholder="Number of Employees"
                                    onChange={this.update_model_attribute(
                                        'company_employee_count'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_employee_count'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Company Stage (i.e. Seed, Series A, etc.)</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_stage}
                                    placeholder="Company Stage"
                                    onChange={this.update_model_attribute(
                                        'company_stage'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_stage'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>
                                    Product Stage (i.e. Wireframe, Beta Testing,
                                    etc.)
                                </p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.product_stage}
                                    placeholder="Product Stage"
                                    onChange={this.update_model_attribute(
                                        'product_stage'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'product_stage'
                                    )}
                                />
                                <div className="tiny-gap" />
                            </Card>
                            <div className="tiny-gap" />

                            <Card>
                                <p>Company R&D Spend</p>
                                <div className="very-tiny-gap" />
                                <InputGroup
                                    large={true}
                                    value={this.props.model.company_rd_spend}
                                    placeholder="R&D Spend"
                                    onChange={this.update_model_attribute(
                                        'company_rd_spend'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_rd_spend'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Procurement Process</p>
                                <div className="very-tiny-gap" />
                                <TextArea
                                    large={true}
                                    value={
                                        this.props.model
                                            .company_procurement_process
                                    }
                                    placeholder="Procurement Process"
                                    onChange={this.update_model_attribute(
                                        'company_procurement_process'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_procurement_process'
                                    )}
                                />
                                <div className="tiny-gap" />

                                <p>Risk Profile Acceptance</p>
                                <div className="very-tiny-gap" />
                                <TextArea
                                    large={true}
                                    value={
                                        this.props.model
                                            .company_risk_profile_acceptance
                                    }
                                    placeholder="Risk Profile Acceptance"
                                    onChange={this.update_model_attribute(
                                        'company_risk_profile_acceptance'
                                    )}
                                    onBlur={this.save_model_attribute(
                                        'company_risk_profile_acceptance'
                                    )}
                                />
                                <div className="tiny-gap" />
                            </Card>
                        </div>
                    </div>

                    <div className="tiny-gap" />
                </div>
            </div>
        )
    }

    render() {
        if (this.props.model.can_edit) return this.render_editable()
        return this.render_static()
    }
}

export default observer(Overview)
