import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { handle_error } from '../../contrib/utils'

import state from '../../contrib/state'
import history from '../../contrib/history'

import { Button, InputGroup, Intent, Card, Popover } from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'

class Members extends Overview {
    state = observable({
        members: [],
        new_member_email: '',
    })

    load = async () => {
        this.state.members = await this.model().get_members()
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    kick = (member) => {
        return async () => {
            await this.model().kick_member(member.email)
            this.load()
        }
    }

    invite_member = async () => {
        try {
            await this.model().invite_member(this.state.new_member_email)
            await this.load()
            this.state.new_member_email = ''
        } catch (e) {
            handle_error(e)
        }
    }

    render_invite_member = () => {
        return (
            <div className="row">
                <div className="col col-xs-7">
                    <InputGroup
                        large={true}
                        placeholder="New Member Email"
                        value={this.state.new_member_email}
                        onChange={(e) => {
                            this.state.new_member_email = e.target.value
                        }}
                    />
                </div>
                <div className="col col-xs-5">
                    <Button
                        intent={Intent.SUCCESS}
                        large={true}
                        className="bp3-fill"
                        onClick={this.invite_member}
                    >
                        Invite New Member
                    </Button>
                </div>
            </div>
        )
    }

    render_member = (member) => {
        return (
            <tr>
                <td>
                    {member.first_name} {member.last_name}
                </td>
                <td>{member.email}</td>
                <td>{member.roles}</td>
                <td>
                    <Popover>
                        <Button intent={Intent.DANGER}>Kick</Button>
                        <div style={{ width: '300px', padding: '16px' }}>
                            <p>
                                Are you sure you want to kick this member? This
                                will remove them from this community
                            </p>
                            <div className="tiny-gap" />
                            <div className="end-xs">
                                <Button
                                    intent={Intent.DANGER}
                                    onClick={this.kick(member)}
                                >
                                    I am sure
                                </Button>
                            </div>
                        </div>
                    </Popover>
                </td>
            </tr>
        )
    }

    render_members = () => {
        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.members.map((member) =>
                        this.render_member(member)
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <Card>
                <div className="row">
                    <div className="col col-xs-6">
                        <h3>Manage Community Members</h3>
                        <p>Invite or kick members from your community</p>
                    </div>
                    <div className="col col-xs-6">
                        <div className="end-xs">
                            <div className="tiny-gap" />
                            <div className="tiny-gap" />
                            {this.render_invite_member()}
                        </div>
                    </div>
                </div>

                <div className="tiny-gap" />
                {this.render_members()}
            </Card>
        )
    }
}

export default observer(Members)
