import axios from 'axios'
import conf from '../config'
import state from './state'
import Amplify, { Auth } from 'aws-amplify'

function check_endpoint(success, failure) {
    axios.get(window.core_url + '/users/').then(
        (res) => success(res),
        (err) => {
            if (state.prompt_login) return // do not spam reload if the user has not logged in
            state.loading = true
            check_endpoint(success, failure)
        }
    )
    success()
}

window.core_url = conf.core_url
axios.defaults.baseURL = window.core_url
check_endpoint(
    (success) => {
        if (state) state.resolved_host = true
    },
    (err) => {}
)

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-1',
        userPoolId: 'us-east-1_Kbz4SHsCl',
        userPoolWebClientId: 'rstbfb95ch9pdddjs5mv7bvcn',
    },
})

Auth.currentSession()
    .then((data) => {
        axios.defaults.headers.common['Authorization'] =
            'bearer ' + data.idToken.jwtToken
    })
    .catch((err) => console.log(err))

setInterval(async function () {
    // If we aren't logged in, don't bother refreshing the token.  It
    // will be refreshed in 5 minutes once we log in.
    Auth.currentSession()
        .then((data) => {
            axios.defaults.headers.common['Authorization'] =
                'bearer ' + data.idToken.jwtToken
        })
        .catch((err) => {})
}, 30000)

export default axios
