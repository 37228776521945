import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { handle_error } from '../../contrib/utils'
import { Bounty } from '../../contrib/models'
import { Auth } from 'aws-amplify'

import state from '../../contrib/state'
import history from '../../contrib/history'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    Popover,
    NonIdealState,
    Spinner,
} from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'

class Bounties extends Overview {
    state = observable({
        loading: false,
        actions_loading: {},
        models: [],
        page: 1,
    })

    load = async () => {
        await Auth.currentSession()
        this.state.loading = true
        this.state.models = await Bounty.list({ pending: true })
        this.state.loading = false
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_bounty = (bounty) => {
        return (
            <tr>
                <td>{bounty.name}</td>
                <td>{bounty.location}</td>
                <td>{bounty.budget}</td>
                <td>
                    <Button onClick={() => history.push(bounty.client_path)}>
                        View Bounty
                    </Button>
                </td>
            </tr>
        )
    }

    render_bounties = () => {
        if (this.state.loading) {
            return (
                <div>
                    <div className="small-gap" />
                    <NonIdealState icon={<Spinner />} title="Loading..." />
                    <div className="small-gap" />
                </div>
            )
        }

        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Budget</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.models.map((bounty) =>
                        this.render_bounty(bounty)
                    )}
                </tbody>
            </table>
        )
    }

    render_pagination = () => {
        return <div></div>
    }

    render() {
        return (
            <Card>
                <div className="row">
                    <div className="col col-xs-6">
                        <h3>Pending Bounties</h3>
                        <p>
                            Bounties that have not been approved to be posted on
                            BountyBoard yet
                        </p>
                    </div>
                </div>

                <div className="tiny-gap" />
                {this.render_bounties()}
                <div className="tiny-gap" />
                {this.render_pagination()}
            </Card>
        )
    }
}

export default observer(Bounties)
