import React from 'react'

import state from '../../contrib/state'
import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    Overlay,
    Toaster,
    Position,
} from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'
import Editor from 'rich-markdown-editor'

import { BountyApplication } from '../../contrib/models'
import { handle_error } from '../../contrib/utils'

let toaster = Toaster.create({ position: Position.TOP })

class Members extends Overview {
    state = observable({
        is_open: false,
        proposal_link: '',
    })

    submit_solution = async () => {
        try {
            await BountyApplication.create({
                bounty: this.model().object_id,
                proposal_link: this.state.proposal_link,
            })
            toaster.show({
                message: (
                    <div>
                        Thank you for declaring interest in this Bounty. Your
                        company profile has been forwarded to the solution
                        seeker. You will be sent the full application if the
                        solution seeker is interested in proceeding further.
                    </div>
                ),
                className: 'bp3-dark',
            })
            await this.model().sync()
            this.state.is_open = false
        } catch (e) {
            handle_error(e)
        }
    }

    render_menu_button = () => {
        if (this.model().offered_proposal) {
            return (
                <Button
                    large={true}
                    intent={Intent.SUCCESS}
                    onClick={() =>
                        history.push(
                            '/proposal/' + this.model().offered_proposal
                        )
                    }
                >
                    View My Proposal
                </Button>
            )
        }
        if (this.model().offered_solution) {
            return (
                <Button large={true} intent={Intent.SUCCESS} disabled={true}>
                    Already Offered a Solution
                </Button>
            )
        }
        return (
            <Button
                large={true}
                intent={Intent.SUCCESS}
                onClick={() => (this.state.is_open = true)}
            >
                Offer a Solution
            </Button>
        )
    }

    render() {
        // if (this.state.applications.length == 0) return <div />
        return (
            <div>
                <Overlay
                    isOpen={this.state.is_open}
                    onClose={() => (this.state.is_open = false)}
                >
                    <Card>
                        <div className="bp3-text-large bp3-running-text">
                            <h3>Offer a Solution</h3>
                            <p>
                                The solution seeker has indicated the following
                                eligibility requirements. Please review them
                                carefully to make sure you are eligible to
                                submit a proposal.
                            </p>

                            <p>
                                Once your proposal is submitted, BountyBoard
                                will reach out to the solution seeker to arrange
                                the next steps.
                            </p>

                            <Card>
                                <Editor
                                    value={this.model().eligibility}
                                    placeholder="Who is eligible to offer a solution?"
                                    readOnly={true}
                                />
                            </Card>

                            <div className="tiny-gap" />

                            <h5 className="bp3-text-muted">
                                Additional Documents (Optional)
                            </h5>
                            <p className="bp3-text-muted">
                                If you have additional documents that you want
                                to submit as part of your solution, please enter
                                a link to it below. (Google Docs, Google Drive,
                                Powerpoint Spreadsheet, etc.). Please make sure
                                the attached document is not password protected
                                and anyone with a link can access it.
                            </p>
                            <div className="tiny-gap" />
                            <InputGroup
                                large={true}
                                value={this.state.proposal_link}
                                placeholder="Additional Documents"
                                onChange={(event) =>
                                    (this.state.proposal_link =
                                        event.target.value)
                                }
                            />

                            <div className="tiny-gap" />
                            <div className="end-xs">
                                <Button
                                    large={true}
                                    intent={Intent.SUCCESS}
                                    onClick={this.submit_solution}
                                >
                                    Offer a Solution
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Overlay>
                {this.render_menu_button()}
            </div>
        )
    }
}

export default observer(Members)
