import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { Button, Divider } from '@blueprintjs/core'

import moment from 'moment'

class BountyCard extends React.Component {
    render_deadline = () => {
        if (this.props.model.deadline === '2100-01-01') return 'No Deadline'
        var m_obj = moment(this.props.model.deadline)
        if (m_obj.isAfter()) {
            return 'Expires ' + moment(this.props.model.deadline).fromNow()
        }
        return 'Expired ' + moment(this.props.model.deadline).fromNow()
    }

    render() {
        return (
            <div
                className="bounty-card bp3-text-small"
                onClick={() => history.push(this.props.model.client_path)}
            >
                <h2 className="title">{this.props.model.name}</h2>
                <p className="description">
                    {this.props.model.description.substring(0, 64)}...
                </p>

                <div className="very-tiny-gap" />
                <div className="bp3-text-muted border-left">
                    {this.render_deadline()}
                </div>
            </div>
        )
    }
}

export default observer(BountyCard)
