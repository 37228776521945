import '../../contrib/http'
import React from 'react'

import state from '../../contrib/state'

import RecommendedBounties from '../components/recommended_bounties'
import AppliedBounties from '../components/applied_bounties'
import PostedBounties from '../components/posted_bounties'

import { observer } from 'mobx-react'

class App extends React.Component {
    render() {
        var my_bounties = <PostedBounties />
        if (!state.current_user || !state.current_user.solution_seeker)
            my_bounties = <AppliedBounties />

        return (
            <div className="content-view">
                <div className="tiny-gap" />
                <div className="row">
                    <div className="col col-xs-4">{my_bounties}</div>
                    <div className="col col-xs-8">
                        <RecommendedBounties />
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(App)
