import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import {
    Button,
    Intent,
    Drawer,
    ButtonGroup,
    Toaster,
    Position,
} from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'

let toaster = Toaster.create({ position: Position.TOP })

class Members extends Overview {
    state = observable({
        applications: [],
        is_open: false,
    })

    load = async () => {
        this.state.applications = await this.model().get_applications()
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_application = (app) => {
        return (
            <tr>
                <td>
                    {app.owner.first_name} {app.owner.last_name}
                </td>
                <td>{app.owner.company_name}</td>
                <td>{app.owner.company_website}</td>

                <td>
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                history.push('/users/' + app.owner.object_id)
                            }}
                            outlined={true}
                            large={true}
                            minimal={true}
                        >
                            Profile
                        </Button>
                        <Button
                            onClick={async () => {
                                await app.accept()

                                toaster.show({
                                    message: (
                                        <div>
                                            Thank you for declaring interest.
                                            The innovator has been notified to
                                            complete a full proposal for your
                                            Bounty. As soon as the proposal is
                                            completed, we will reach out.
                                        </div>
                                    ),
                                })

                                this.load()
                            }}
                            outlined={true}
                            intent={Intent.SUCCESS}
                            large={true}
                            minimal={true}
                        >
                            Request Proposal
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        )
    }

    render_applications = () => {
        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>

                        <th>Company Name</th>
                        <th>Company Website</th>

                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.applications.map((m) =>
                        this.render_application(m)
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        // if (this.state.applications.length == 0) return <div />
        return (
            <div>
                <Drawer
                    isOpen={this.state.is_open}
                    onClose={() => (this.state.is_open = false)}
                    usePortal={true}
                >
                    <div className="content">
                        <div className="row">
                            <div className="col col-xs-6">
                                <h3>Interested Innovators</h3>
                                <p>
                                    The following Bounty innovators have
                                    declared interest in offering a solution.
                                    The BountyBoard team will work together with
                                    these innovators to put together a proposal.
                                </p>
                            </div>
                            <div className="col col-xs-6"></div>
                        </div>

                        <div className="tiny-gap" />
                        {this.render_applications()}
                    </div>
                </Drawer>
                <Button
                    large={true}
                    outlined={true}
                    intent={Intent.SUCCESS}
                    onClick={() => (this.state.is_open = true)}
                >
                    {this.state.applications.length} Interested
                </Button>
            </div>
        )
    }
}

export default observer(Members)
