import React from 'react'
import axios from 'axios'

import state from '../state'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { handle_error } from '../utils'

import {
    Overlay,
    Intent,
    Button,
    Toaster,
    TextArea,
    Card,
    Position,
} from '@blueprintjs/core'

let toaster = Toaster.create({ position: Position.TOP })

export default observer(
    class Support extends React.Component {
        state = observable({
            content: '',
            is_open: false,
        })

        submit_ticket = async () => {
            try {
                await axios.post('/support/tickets/', {
                    content: this.state.content,
                })

                toaster.show({
                    message: (
                        <div>
                            Thank you for contacting BountyBoard. Your ticket
                            was submitted. We will contact you as soon as
                            possible.
                        </div>
                    ),
                    className: 'bp3-dark',
                })

                // close the modal and reset state
                this.state.is_open = false
                this.state.content = ''
                this.forceUpdate()
            } catch (e) {
                // handle_error(e)
            }
        }

        render() {
            return (
                <div className="fixed-buttons bottom">
                    <div>
                        <Button onClick={() => (this.state.is_open = true)}>
                            Need Assistance?
                        </Button>
                        <div className="tiny-gap" />
                        <Button
                            onClick={() =>
                                window.open('https://discord.gg/xndNn5egrS')
                            }
                        >
                            Join Discord Community
                        </Button>
                        <div className="tiny-gap" />
                        <Button
                            onClick={() =>
                                window.open(
                                    'https://bountyboardwiki.notion.site/bountyboardwiki/Resource-Wiki-713eb6d9f9c34af6845bec5f87e3cb8b'
                                )
                            }
                        >
                            BountyBoard Community Wiki
                        </Button>
                    </div>
                    <Overlay
                        isOpen={this.state.is_open}
                        onClose={() => (this.state.is_open = false)}
                    >
                        <Card>
                            <div className="bp3-text-large bp3-running-text">
                                <h3>Need Assistance?</h3>
                                <p>
                                    Please drop us a line below with any
                                    questions, feedback, or bug reports. We will
                                    get back to you as soon as possible.
                                </p>

                                <TextArea
                                    large={true}
                                    value={this.state.content}
                                    placeholder="How can we help?"
                                    onChange={(event) =>
                                        (this.state.content =
                                            event.target.value)
                                    }
                                />

                                <div className="tiny-gap" />
                                <div className="end-xs">
                                    <Button
                                        onClick={this.submit_ticket}
                                        disabled={
                                            this.state.content.length === 0
                                        }
                                        intent={Intent.SUCCESS}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </Overlay>
                </div>
            )
        }
    }
)
