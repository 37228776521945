import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import state from '../../contrib/state'
import { Auth } from 'aws-amplify'
import {
    Button,
    Divider,
    InputGroup,
    Intent,
    Position,
    Toaster,
} from '@blueprintjs/core'

import history from '../../contrib/history'
import PasswordCheck from '../components/password_check'

export default observer(
    class Login extends React.Component {
        state = observable({
            email: '',
            display_second_step: false,
            confirm_code: '',
            password: '',
        })

        get_code = async (e) => {
            state.loading = true
            // stop default submit
            e.preventDefault()
            try {
                await Auth.forgotPassword(this.state.email)
                this.state.display_second_step = true
            } catch (e) {
                var message = e
                if (e.message) message = e.message
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.DANGER,
                    message: message,
                })
            }
            state.loading = false
        }

        complete_password_reset = async (e) => {
            state.loading = true
            e.preventDefault()
            try {
                await Auth.forgotPasswordSubmit(
                    this.state.email,
                    this.state.confirm_code,
                    this.state.password
                )
                // show message about operation being successful
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.SUCCESS,
                    message:
                        'Successfully changed your password. Please wait while you get redirected.',
                })
                await Auth.signIn({
                    username: this.state.email,
                    password: this.state.password,
                })
                // authenticate the user
                // reload the page
                history.push('/')
                window.location.reload()
            } catch (e) {
                var message = e
                if (e.message) message = e.message
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.DANGER,
                    message: message,
                })
            }
            state.loading = false
        }

        render() {
            var confirm_inputs = <div />
            var action_button = (
                <Button
                    intent={Intent.PRIMARY}
                    onClick={this.get_code}
                    type="submit"
                >
                    Reset Password
                </Button>
            )
            if (this.state.display_second_step) {
                confirm_inputs = (
                    <div>
                        <div className="tiny-gap" />
                        <InputGroup
                            large={true}
                            placeholder="Confirmation Code (Emailed to You)"
                            onChange={(event) =>
                                (this.state.confirm_code = event.target.value)
                            }
                        />
                        <div className="tiny-gap" />
                        <InputGroup
                            large={true}
                            leftIcon="lock"
                            placeholder="Your New Password"
                            onChange={(event) =>
                                (this.state.password = event.target.value)
                            }
                            type="password"
                        />
                        <div className="tiny-gap" />
                        <PasswordCheck password={this.state.password} />
                    </div>
                )
                action_button = (
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={this.complete_password_reset}
                        type="submit"
                    >
                        Set New Password
                    </Button>
                )
            }
            return (
                <div className="row center-xs">
                    <div className="cole-lg-4 col-md-6 col-sm-12 start-xs">
                        <div className="medium-gap" />
                        <h2 className="bp3-heading">Reset Your Password</h2>
                        <p>Please enter your email address to get started</p>

                        <div className="small-gap" />
                        <form onSubmit={this.login}>
                            <InputGroup
                                large={true}
                                leftIcon="user"
                                placeholder="Your Email Address"
                                onChange={(event) =>
                                    (this.state.email = event.target.value)
                                }
                                type="email"
                            />
                            {confirm_inputs}

                            <div className="tiny-gap" />
                            <div className="end-xs">{action_button}</div>
                            <div className="tiny-gap" />
                            <Divider vertical={true} />
                            <div className="tiny-gap" />
                            <Button
                                intent={Intent.NONE}
                                onClick={() =>
                                    history.push('/users/actions/login')
                                }
                                minimal={true}
                            >
                                Already have account? Login here
                            </Button>
                        </form>
                    </div>
                </div>
            )
        }
    }
)
