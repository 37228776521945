import React from 'react'
import state from '../../contrib/state'

import Overview from '../../contrib/components/overview'

import CommunityHeader from '../components/header'
import CommunityMembers from '../components/members'
import CommunityInvitations from '../components/invitations'
import SlackIntegration from '../components/slack'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

class CommunityOverview extends Overview {
    ui = observable({})

    queryset = () => {
        return state.communities
    }

    render() {
        if (this.model()) {
            return (
                <div>
                    <CommunityHeader model={this.model()} editable={true} />
                    <div className="content">
                        <CommunityInvitations model={this.model()} />
                        <div className="tiny-gap" />
                        <CommunityMembers model={this.model()} />
                        <div className="tiny-gap" />
                        <SlackIntegration model={this.model()} />
                    </div>
                </div>
            )
        }
        return <div />
    }
}

export default observer(CommunityOverview)
