import React from 'react'
import { observer } from 'mobx-react'
import { Toaster, Position, Intent, Spinner } from '@blueprintjs/core'
import state from '../state'

let loading_toaster = Toaster.create({
    position: Position.BOTTOM_RIGHT,
})

export default observer(
    class Indicator extends React.Component {
        render() {
            if (state.loading) {
                loading_toaster.show({
                    message: (
                        <div className="row">
                            <div className="col col-xs-4">
                                <Spinner intent={Intent.PRIMARY} size={50} />
                            </div>
                            <div className="col col-xs-8">
                                Loading... Please wait while BountyBoard
                                finishes loading
                            </div>
                        </div>
                    ),
                    intent: Intent.NONE,
                })
                return <div />
            } else {
                loading_toaster.clear()
                return <div />
            }
        }
    }
)
