import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import state from '../../contrib/state'
import { Auth } from 'aws-amplify'
import { handle_error } from '../../contrib/utils'
import {
    Button,
    Divider,
    InputGroup,
    Intent,
    Position,
    Toaster,
} from '@blueprintjs/core'

import history from '../../contrib/history'
import PasswordCheck from '../components/password_check'

export default observer(
    class Login extends React.Component {
        state = observable({
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            display_confirm_code: false,
            confirm_code: '',
        })

        signup = async (e) => {
            state.loading = true
            e.preventDefault()
            try {
                await Auth.signUp({
                    username: this.state.email,
                    password: this.state.password,
                    attributes: {
                        updated_at: '',
                        given_name: this.state.first_name,
                        family_name: this.state.last_name,
                        email: this.state.email,
                        name:
                            this.state.first_name + ' ' + this.state.last_name,
                    },
                })
                this.state.display_confirm_code = true
            } catch (e) {
                var message = e
                if (e.message) message = e.message
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.DANGER,
                    message: message,
                })
            }
            state.loading = false
        }

        complete_signup = async (e) => {
            state.loading = true
            e.preventDefault()
            try {
                await Auth.confirmSignUp(
                    this.state.email,
                    this.state.confirm_code
                )
                // show message about operation being successful
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.SUCCESS,
                    message:
                        'Successfully confirmed your account. Please wait while you get redirected.',
                })
                await Auth.signIn({
                    username: this.state.email,
                    password: this.state.password,
                })
                // authenticate the user
                // reload the page
                history.push('/')
                window.location.reload()
            } catch (e) {
                Toaster.create({ position: Position.TOP }).show({
                    intent: Intent.DANGER,
                    message: e.message,
                })
            }
            state.loading = false
        }

        render_confirm = () => {
            return (
                <div className="content-view">
                    <div className="row center-xs">
                        <div
                            className="cole-lg-4 col-md-6 col-sm-12 start-xs"
                            key="confirm"
                        >
                            <div className="medium-gap" />
                            <h2 className="bp3-heading">Confirm Your Email</h2>
                            <p>
                                Please the code that was email below to confirm
                                your account
                            </p>

                            <div className="small-gap" />
                            <form onSubmit={this.login}>
                                <InputGroup
                                    large={true}
                                    placeholder="Confirm Code"
                                    onChange={(event) =>
                                        (this.state.confirm_code =
                                            event.target.value)
                                    }
                                />
                                <div className="tiny-gap" />

                                <div className="tiny-gap" />
                                <div className="end-xs">
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={this.complete_signup}
                                        type="submit"
                                    >
                                        Confirm
                                    </Button>
                                </div>

                                <div className="tiny-gap" />
                                <Divider vertical={true} />
                                <div className="tiny-gap" />
                                <Button
                                    intent={Intent.NONE}
                                    onClick={() =>
                                        history.push('/users/actions/login')
                                    }
                                    minimal={true}
                                >
                                    Already have account? Login here
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }

        render_signup = () => {
            return (
                <div className="row center-xs">
                    <div className="col-lg-4 col-md-6 col-sm-12 start-xs">
                        <div className="medium-gap" />
                        <div className="authentication-card card">
                            <img
                                src="/assets/logo.png"
                                className="logo-image"
                            />
                            <div className="tiny-gap" />
                            <form onSubmit={this.login}>
                                <p className="bp3-text-muted">
                                    Login or sign up for a free account to
                                    collaborate with other innovative minds and
                                    create real lasting change.
                                </p>
                                <div className="tiny-gap" />

                                <InputGroup
                                    large={true}
                                    placeholder="First Name"
                                    onChange={(event) =>
                                        (this.state.first_name =
                                            event.target.value)
                                    }
                                />
                                <div className="tiny-gap" />
                                <InputGroup
                                    large={true}
                                    placeholder="Last Name"
                                    onChange={(event) =>
                                        (this.state.last_name =
                                            event.target.value)
                                    }
                                />

                                <div className="tiny-gap" />
                                <InputGroup
                                    large={true}
                                    leftIcon="user"
                                    placeholder="Your Email Address"
                                    onChange={(event) =>
                                        (this.state.email = event.target.value)
                                    }
                                    type="email"
                                />
                                <div className="tiny-gap" />
                                <InputGroup
                                    large={true}
                                    leftIcon="lock"
                                    placeholder="Your Password"
                                    onChange={(event) =>
                                        (this.state.password =
                                            event.target.value)
                                    }
                                    type="password"
                                />

                                <div className="tiny-gap" />
                                <PasswordCheck password={this.state.password} />

                                <div className="tiny-gap" />
                                <div className="end-xs">
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={this.signup}
                                        type="submit"
                                        large={true}
                                    >
                                        Sign Up
                                    </Button>
                                </div>

                                <div className="tiny-gap" />
                                <Divider vertical={true} />
                                <div className="tiny-gap" />

                                <Button
                                    intent={Intent.NONE}
                                    onClick={() =>
                                        history.push('/users/actions/login')
                                    }
                                    minimal={true}
                                >
                                    Already have account? Login here
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }

        render() {
            var inner_render = this.render_signup()
            if (this.state.display_confirm_code)
                inner_render = this.render_confirm()
            return inner_render
        }
    }
)
