import React from 'react'
import state from '../../contrib/state'
import {
    Navbar,
    Alignment,
    Button,
    Intent,
    EditableText,
    Callout,
    Position,
    Popover,
    Toaster,
    Card,
    Divider,
    Checkbox,
} from '@blueprintjs/core'

import { Bounty, BountyApplication } from '../../contrib/models'
import Overview from '../../contrib/components/overview'
import Applications from '../components/applications'
import Proposals from '../components/proposals'
import Apply from '../components/apply'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { handle_error } from '../../contrib/utils'

import Editor from 'rich-markdown-editor'
import history from '../../contrib/history'
import App from '../../feed/views/app'
import { DatePicker } from '@blueprintjs/datetime'
import moment from 'moment'

let toaster = Toaster.create({ position: Position.TOP })

class CommunityOverview extends Overview {
    ui = observable({})
    state = observable({
        temp_contnet: '',
        temp_content_changed: false,
        temp_eligibility: '',
        temp_eligibility_changed: false,
        temp_budget: '',
        temp_budget_changed: false,
        temp_location: '',
        temp_location_changed: false,
        temp_deadline: new Date(),
    })
    model_class = Bounty

    save_model_attribute(attr) {
        return async (value) => {
            this.model()[attr] = value
            try {
                await this.model().save([attr])
                this.forceUpdate()
            } catch (e) {
                handle_error(e)
            }
        }
    }

    render_bounty_status_staff = () => {
        if (state.current_user.is_staff && !this.model().approved) {
            return (
                <div className="content-view">
                    <Callout intent={Intent.WARNING}>
                        You can only see this since you are a BountyBoard staff.
                        Please approve this Bounty if it all looks good
                        <div className="end-xs">
                            <Button
                                large={true}
                                intent={Intent.WARNING}
                                onClick={() => {
                                    this.model().approved = true
                                    this.model().save(['approved'])
                                    this.forceUpdate()
                                }}
                            >
                                Approve Bounty
                            </Button>
                        </div>
                    </Callout>
                    <div className="tiny-gap" />
                </div>
            )
        }
        return <div />
    }

    render_bounty_status = () => {
        if (!this.model().approved) {
            return (
                <div className="content-view">
                    <div className="tiny-gap" />
                    <Callout intent={Intent.SUCCESS}>
                        Thank you for posting a Bounty. Your Bounty is currently
                        being reviewed by the BountyBoard staff and as soon as
                        everything looks good, it will be posted to the
                        BountyBoard network.
                    </Callout>
                    <div className="tiny-gap" />
                </div>
            )
        }
        return <div />
    }

    render_owner_profile = () => {
        return <div />
        if (!this.model().owner) return <div />
        return (
            <Card>
                <strong>{this.model().owner.company_name}</strong>

                <p>{this.model().owner.company_description}</p>

                <div className="end-xs">
                    <Button
                        onClick={() => {
                            window.open(this.model().owner.company_website)
                        }}
                        outlined={true}
                    >
                        Visit Website
                    </Button>
                </div>
            </Card>
        )
    }

    after_model_updated = async () => {
        this.state.temp_deadline = new Date(this.model().deadline)
    }

    update_model_attribute(attr) {
        return (value) => {
            this.model()[attr] = value
            this.forceUpdate()
        }
    }

    render_share_to_communities = () => {
        if (state.current_user.is_staff) {
            return (
                <Popover position="bottom">
                    <Button large={true} outlined={true}>
                        Share to Partnered Communities
                    </Button>
                    <div className="content popover-small">
                        Are you really sure you want post this Bounty to all the
                        connected community slack channels?
                        <div className="tiny-gap" />
                        <div className="end-xs">
                            <Button
                                intent={Intent.SUCCESS}
                                onClick={() => {
                                    alert('Done!')
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Popover>
            )
        }
        return <div />
    }

    render_incomplete_profile_warning = () => {
        if (!state.current_user.is_profile_complete) {
            return (
                <Callout intent={Intent.WARNING}>
                    Please complete your solution provider profile before
                    attempting to apply to this Bounty. Your profile can be
                    completed on the left hand side menu.
                </Callout>
            )
        }
    }

    render_deadline_text = () => {
        if (this.model().deadline === '2100-01-01') return 'No Deadline'
        return 'Deadline: ' + moment(this.model().deadline).fromNow()
    }

    render_budget = () => {
        if (this.model().hide_budget) {
            return (
                <div>
                    <div className="tiny-gap" />
                    <Divider />
                    <div className="tiny-gap" />
                </div>
            )
        }
        return (
            <div>
                <Card elevation={2}>
                    <strong>Budget</strong>
                    <div className="tiny-gap" />
                    <Editor value={this.model().budget} readOnly={true} />
                </Card>
                <div className="tiny-gap" />
            </div>
        )
    }

    render_static_location = () => {
        if (this.model().location === 'remote') {
            return (
                <Popover position="bottom">
                    <Button outlined={true}>Remote Bounty</Button>
                    <div className="popover-small content">
                        Bounty Poster has made this bounty available to people
                        from all locations
                    </div>
                </Popover>
            )
        }
        return (
            <Popover position="bottom">
                <Button outlined={true} intent={Intent.DANGER}>
                    Non-Remote Bounty
                </Button>
                <div className="popover-small content">
                    <Editor value={this.model().location} readOnly={true} />
                </div>
            </Popover>
        )
    }

    render_static = () => {
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                {this.render_deadline_text()}
                            </Navbar.Heading>
                            {this.render_static_location()}
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Apply model={this.model()} />
                        </Navbar.Group>
                    </div>
                </Navbar>
                <div className="content-view bp3-text-large bp3-running-text">
                    <h1>{this.model().name}</h1>
                    <h5>{this.model().description}</h5>

                    {this.render_budget()}

                    <Editor value={this.model().content} readOnly={true} />

                    <div className="tiny-gap" />
                    {this.render_owner_profile()}
                    <div className="tiny-gap" />
                </div>
            </div>
        )
    }

    perform_delete = async () => {
        try {
            await this.model().destroy()
            history.push('/')
        } catch (e) {
            handle_error(e)
        }
    }

    render_change_deadline = () => {
        var intent = Intent.WARNING
        if (moment(this.model().deadline).isBefore()) intent = Intent.DANGER

        return (
            <Popover position="bottom">
                <Button
                    large={true}
                    intent={intent}
                    icon="calendar"
                    minimal={true}
                >
                    {this.render_deadline_text()}
                </Button>
                <div className="content popover-small">
                    <strong>Submissions Deadline</strong>
                    <p>
                        Submissions of new proposals will be closed after this
                        date.
                    </p>
                    <div className="tiny-gap" />
                    <DatePicker
                        value={this.state.temp_deadline}
                        onChange={(date) => {
                            this.state.temp_deadline = date
                            this.model().deadline = date
                                .toISOString()
                                .split('T')[0]
                            this.model().save(['deadline'])
                        }}
                    />
                    <div className="tiny-gap" />
                    <div>
                        <Button
                            onClick={async () => {
                                this.model().deadline = '2100-01-01'
                                this.model().save(['deadline'])
                                this.forceUpdate()
                            }}
                        >
                            No Deadline
                        </Button>
                    </div>
                </div>
            </Popover>
        )
    }

    render_delete = () => {
        return (
            <Popover position="bottom">
                <Button
                    large={true}
                    icon="trash"
                    outlined={true}
                    intent={Intent.DANGER}
                />
                <div className="content popover-small">
                    Are you really sure you want to delete this bounty? This
                    action is not reversible.
                    <div className="tiny-gap" />
                    <div className="end-xs">
                        <Button
                            intent={Intent.DANGER}
                            large={true}
                            onClick={() => this.perform_delete()}
                        >
                            Permanently Delete
                        </Button>
                    </div>
                </div>
            </Popover>
        )
    }

    render_bounty_location_textbox = () => {
        if (this.model().location === 'remote') return <div />
        return (
            <div>
                <div className="tiny-gap" />
                <Editor
                    value={this.model().location}
                    placeholder="What is the location for this Bounty?"
                    readOnly={false}
                    onChange={(v) => {
                        this.state.temp_location = v()
                        this.state.temp_location_changed = true
                    }}
                    onBlur={() => {
                        if (this.state.temp_location_changed) {
                            this.model().location = this.state.temp_location
                            this.model().save(['location'])
                        }
                    }}
                />
            </div>
        )
    }

    render_editable = () => {
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                {this.render_change_deadline()}
                            </Navbar.Heading>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Applications model={this.model()} />
                            <div className="horizontal-gap" />
                            <Proposals model={this.model()} />
                            <div className="horizontal-gap" />
                            {this.render_delete()}
                            <div className="horizontal-gap" />
                            {this.render_share_to_communities()}
                        </Navbar.Group>
                    </div>
                </Navbar>
                {this.render_bounty_status()}
                {this.render_bounty_status_staff()}
                <div className="content-view bp3-text-large bp3-running-text">
                    <h1>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.model().name}
                            placeholder={'Please enter a title for your bounty'}
                            onChange={this.update_model_attribute('name')}
                            onConfirm={this.save_model_attribute('name')}
                        />
                    </h1>

                    <p>
                        <EditableText
                            selectAllOnFocus={true}
                            value={this.model().description}
                            multiline={true}
                            placeholder={
                                'Please enter a short summary for your bounty'
                            }
                            onChange={this.update_model_attribute(
                                'description'
                            )}
                            onConfirm={this.save_model_attribute('description')}
                        />
                    </p>

                    <div className="tiny-gap" />
                    <Card elevation={2}>
                        <strong>Eligibility Requirements</strong>
                        <div className="tiny-gap" />
                        <Editor
                            value={this.model().eligibility}
                            placeholder="Who is eligible to offer a solution?"
                            readOnly={false}
                            onChange={(v) => {
                                this.state.temp_eligibility = v()
                                this.state.temp_eligibility_changed = true
                            }}
                            onBlur={() => {
                                if (this.state.temp_eligibility_changed) {
                                    this.model().eligibility = this.state.temp_eligibility
                                    this.model().save(['eligibility'])
                                }
                            }}
                        />
                    </Card>
                    <div className="tiny-gap" />

                    <Card elevation={2}>
                        <div className="row">
                            <div className="col col-xs-6">
                                <strong>Bounty Budget</strong>
                            </div>
                            <div className="col col-xs-6 end-xs">
                                <Checkbox
                                    checked={this.model().hide_budget}
                                    label="Hide budget information"
                                    onChange={(event) => {
                                        if (this.model().hide_budget === true) {
                                            this.model().hide_budget = false
                                        } else {
                                            this.model().hide_budget = true
                                        }
                                        this.model().save(['hide_budget'])
                                        this.forceUpdate()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="tiny-gap" />
                        <Editor
                            value={this.model().budget}
                            placeholder="What is the budget for this bounty?"
                            readOnly={false}
                            onChange={(v) => {
                                this.state.temp_budget = v()
                                this.state.temp_budget_changed = true
                            }}
                            onBlur={() => {
                                if (this.state.temp_budget_changed) {
                                    this.model().budget = this.state.temp_budget
                                    this.model().save(['budget'])
                                }
                            }}
                        />
                    </Card>
                    <div className="tiny-gap" />

                    <Card elevation={2}>
                        <div className="row">
                            <div className="col col-xs-6">
                                <strong>Bounty Location</strong>
                            </div>
                            <div className="col col-xs-6 end-xs">
                                <Checkbox
                                    checked={this.model().location === 'remote'}
                                    label="Remote Bounty. Anyone can apply."
                                    onChange={(event) => {
                                        if (
                                            this.model().location === 'remote'
                                        ) {
                                            this.model().location = ''
                                        } else {
                                            this.model().location = 'remote'
                                        }
                                        this.model().save(['location'])
                                        this.forceUpdate()
                                    }}
                                />
                            </div>
                        </div>
                        {this.render_bounty_location_textbox()}
                    </Card>
                    <div className="tiny-gap" />

                    <Editor
                        value={this.model().content}
                        readOnly={false}
                        onChange={(v) => {
                            this.state.temp_content = v()
                            this.state.temp_content_changed = true
                        }}
                        onBlur={() => {
                            if (this.state.temp_content_changed) {
                                this.model().content = this.state.temp_content
                                this.model().save(['content'])
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    render_content = () => {
        if (this.model().can_edit) return this.render_editable()
        return this.render_static()
    }
}

export default observer(CommunityOverview)
