import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { Bounty } from '../../contrib/models'
import BountyList from '../../bounty/components/list'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    NonIdealState,
} from '@blueprintjs/core'
import { Auth } from 'aws-amplify'

class Bounties extends React.Component {
    state = observable({
        models: [],
    })

    load = async () => {
        await Auth.currentSession()
        this.state.models = await Bounty.list({ offered_solution: true })
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_empty_state = () => {
        return (
            <Card>
                <NonIdealState
                    icon="search"
                    title="No Solutions Offered!"
                    description="You have not offered a solution to any bounties yet."
                />
            </Card>
        )
    }

    render() {
        var inner_render
        if (this.state.models.length === 0)
            inner_render = this.render_empty_state()
        else inner_render = <BountyList items={this.state.models} />

        return (
            <div>
                <h3>My Offered Solutions</h3>
                {inner_render}
            </div>
        )
    }
}

export default observer(Bounties)
