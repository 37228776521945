import React from 'react'
import state from '../../contrib/state'
import history from '../../contrib/history'

import { observer } from 'mobx-react'
import { observable } from 'mobx'

import { NonIdealState, Spinner } from '@blueprintjs/core'
import { handle_error } from '../../contrib/utils'

class UserActivation extends React.Component {
    state = observable({
        loading: true,
    })

    activate = async () => {
        try {
            state.current_user.has_access = true
            await state.current_user.save(['has_access'])
            this.state.loading = true

            history.push('/')
            window.location.reload()
        } catch (e) {
            handle_error(e)
        }
    }

    render() {
        if (this.state.loading === true) {
            this.activate()
            return (
                <div className="content-view">
                    <div className="small-gap" />
                    <NonIdealState
                        icon={<Spinner />}
                        title="Activating your account"
                    />
                </div>
            )
        } else {
            return (
                <div className="content-view">
                    <div className="small-gap" />
                    <NonIdealState
                        icon="user"
                        title="Account Activated"
                        description="Your account was successfully activated!"
                    />
                </div>
            )
        }
    }
}

export default observer(UserActivation)
