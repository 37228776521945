import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { BountyProposal } from '../../contrib/models'

import { Button, Intent, Drawer, ButtonGroup } from '@blueprintjs/core'

import Overview from '../../contrib/components/overview'

class Proposals extends Overview {
    state = observable({
        proposals: [],
        is_open: false,
    })

    load = async () => {
        this.state.proposals = await BountyProposal.list({
            bounty: this.model().object_id,
        })
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_proposal = (p) => {
        return (
            <tr>
                <td>
                    {p.owner.first_name} {p.owner.last_name}
                </td>
                <td>{p.owner.company_name}</td>
                <td>{p.owner.company_website}</td>

                <td>
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                history.push(p.client_path)
                            }}
                            outlined={true}
                            large={true}
                            minimal={true}
                        >
                            View Proposal
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        )
    }

    render_proposals = () => {
        return (
            <table className="bp3-html-table full-width bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>

                        <th>Company Name</th>
                        <th>Company Website</th>

                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.proposals.map((m) => this.render_proposal(m))}
                </tbody>
            </table>
        )
    }

    render() {
        // if (this.state.applications.length == 0) return <div />
        return (
            <div>
                <Drawer
                    isOpen={this.state.is_open}
                    onClose={() => (this.state.is_open = false)}
                    usePortal={true}
                >
                    <div className="content">
                        <div className="row">
                            <div className="col col-xs-6">
                                <h3>Proposals</h3>
                                <p>
                                    The following Bounty innovators have
                                    submitted proposals for you to review.
                                </p>
                            </div>
                            <div className="col col-xs-6"></div>
                        </div>

                        <div className="tiny-gap" />
                        {this.render_proposals()}
                    </div>
                </Drawer>
                <Button
                    large={true}
                    outlined={true}
                    intent={Intent.SUCCESS}
                    onClick={() => (this.state.is_open = true)}
                >
                    {this.state.proposals.length} Proposals
                </Button>
            </div>
        )
    }
}

export default observer(Proposals)
