import axios from './http'
import { observable, intercept } from 'mobx'
import { User, Community, Bounty, BountyApplication } from './models'
import { Auth } from 'aws-amplify'
import { Toaster, Position, Intent } from '@blueprintjs/core'

var state = observable({
    loading: false,
    prompt_login: false,

    users: [],
    current_user: {},

    async load_communities() {
        try {
            this.communities = await Community.list()
        } catch (e) {
            // do nothing
        }
    },

    async load_users() {
        try {
            this.users = await User.list({ self: true })
        } catch (e) {
            // do nothing
        }
    },

    async load_rest_state() {
        var current_user = false
        state.loading = true

        try {
            let session = await Auth.currentSession()
        } catch (e) {
            state.prompt_login = true
        }

        await Promise.all([state.load_communities(), state.load_users()])

        try {
            this.current_user = this.users[0]
            window.heap.identify(this.current_user.email)
        } catch (e) {
            // do nothing
        }

        state.loading = false
    },
})

let toaster = Toaster.create({ position: Position.TOP })
setInterval(async function () {
    // If we aren't logged in, don't bother refreshing the token.  It
    // will be refreshed in 10 minutes once we log in.
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const currentSession = await Auth.currentSession()
        cognitoUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
                if (
                    err &&
                    err.code === 'NotAuthorizedException' &&
                    !state.prompt_login
                ) {
                    state.prompt_login = true
                }
            }
        )
    } catch (e) {
        // do nothing
    }
}, 10000)

export default state
