import React from 'react'

import state from '../../contrib/state'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import UsersList from '../components/users'
import BountiesList from '../components/bounties'

import {
    Overlay,
    Intent,
    Button,
    Toaster,
    TextArea,
    Card,
    Position,
    NonIdealState,
} from '@blueprintjs/core'

export default observer(
    class AdminIndex extends React.Component {
        state = observable({
            content: '',
            is_open: false,
        })

        render_no_access = () => {
            return (
                <div className="content-view">
                    <div className="row center-xs">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="medium-gap" />
                            <NonIdealState
                                icon="error"
                                title="Access Denied"
                                description="You are not authorized to view this page."
                            />
                        </div>
                    </div>
                </div>
            )
        }

        render() {
            if (!state.current_user || !state.current_user.is_staff)
                return this.render_no_access()
            return (
                <div className="content-view">
                    <h2>Admin Panel</h2>
                    <div className="tiny-gap" />
                    <UsersList />
                    <div className="tiny-gap" />
                    <BountiesList />
                </div>
            )
        }
    }
)
