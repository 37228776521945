import React from 'react'
import ReactDOM from 'react-dom'
import state from './contrib/state'
import history from './contrib/history'
import config from './config'

import { observer } from 'mobx-react'
import { Route, Switch, Router } from 'react-router-dom'

// css files
import './contrib/styles/index.css'
import './contrib/styles/common.scss'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/table/lib/css/table.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'

// menu
import Menu from './contrib/components/menu'
import Support from './contrib/views/support'

// feed routes
import DefaultApp from './feed/views/app'

// admin routes
import AdminOverview from './admin/views/overview'

// error pages
import Error404 from './contrib/views/404'
import Error502 from './contrib/views/502'

// user related routes
import UserOverview from './user/views/overview'
import AutoActivation from './user/views/auto-activation'
import ForgotPassword from './user/views/forgot_password'
import Login from './user/views/login'
import Signup from './user/views/signup'

// communities routes
import CommunityOverview from './community/views/overview'

// bounties routes
import BountyPost from './bounty/views/post'
import BountyOverview from './bounty/views/overview'

// proposal routes
import ProposalOverview from './proposal/views/overview'

// utils
import LoadingIndicator from './contrib/components/loading_indicator'
import { Alignment, Button, Intent, Navbar } from '@blueprintjs/core'

const public_routes = [
    '/errors/404',
    '/errors/502',
    '/users/actions/login',
    '/users/actions/signup',
    '/users/actions/forgot-password',
]

let routes = (
    <Switch>
        <Route exact path="/" component={DefaultApp} />
        <Route path="/errors/404" component={Error404} />
        <Route path="/errors/502" component={Error502} />

        <Route path="/admin/" component={AdminOverview} />

        <Route exact path="/users/:object_id" component={UserOverview} />
        <Route exact path="/users/actions/login" component={Login} />
        <Route exact path="/users/actions/signup" component={Signup} />
        <Route
            exact
            path="/users/invites/bountyboard"
            component={AutoActivation}
        />
        <Route
            exact
            path="/users/actions/forgot-password"
            component={ForgotPassword}
        />

        <Route path="/community/:object_id" component={CommunityOverview} />

        <Route path="/bounty/post/" component={BountyPost} />
        <Route path="/bounty/:object_id" component={BountyOverview} />

        <Route path="/proposal/:object_id" component={ProposalOverview} />
    </Switch>
)

let BRouter = observer(
    class BRouter extends React.Component {
        componentDidMount() {
            state.load_rest_state()

            history.listen((location, action) => {
                this.ensure_access()
            })
        }

        is_public_route = () => {
            return (
                public_routes
                    .map((path) => window.location.href.indexOf(path) !== -1)
                    .indexOf(true) !== -1
            )
        }

        is_user_profile_route = () => {
            return window.location.href.indexOf('users') !== -1
        }

        requires_login = () => {
            return state.prompt_login && !this.is_public_route()
        }

        requires_access = () => {
            return (
                state.current_user &&
                state.current_user.object_id &&
                !state.current_user.has_access &&
                !this.is_user_profile_route()
            )
        }

        ensure_access = () => {
            if (this.requires_access())
                history.push('/users/' + state.current_user.object_id)
        }

        inner_render = () => {
            if (this.requires_login())
                history.push(
                    '/users/actions/login?redirect_uri=' + window.location.href
                )
            this.ensure_access()

            var authenticated_renderings = [<Menu />, <Support />]
            var view_class = 'app-view'

            if (this.is_public_route()) {
                authenticated_renderings = []
                view_class = ''
            }

            return (
                <div className={view_class}>
                    <Router history={history}>
                        {authenticated_renderings}
                        <LoadingIndicator />
                        <div className="push-down">{routes}</div>
                    </Router>
                </div>
            )
        }

        render() {
            return this.inner_render()
        }
    }
)

ReactDOM.render(<BRouter />, document.getElementById('root'))
