import axios from './http'
import state from './state'
import { handle_error } from './utils'
import { Auth } from 'aws-amplify'

class BaseModel {
    static get fields() {
        return ['object_id', 'updated_at', 'created_at']
    }

    static get namespace() {
        return []
    }

    get api_path() {
        return this.constructor.namespace.join('/') + '/' + this.object_id + '/'
    }

    get client_path() {
        return '/'
    }

    constructor(obj) {
        for (let property in obj) {
            if (
                this.constructor.fields.indexOf(property) !== -1 &&
                obj[property] != null
            )
                this[property] = obj[property]
        }
    }

    static async paginated_list(params, page = 1) {
        let results = await this.list(params, page)
        return {
            results: results,
            count: results.count,
            previous: results.previous,
            next: results.next,
        }
    }

    static async list(params, page = 1) {
        var query_url = this.namespace.join('/') + '/?page=' + page + '&'
        for (let param in params) {
            query_url += param + '=' + params[param] + '&'
        }
        if (query_url[query_url.length - 1] === '&')
            query_url = query_url.substring(0, query_url.length - 1)

        let res = await axios.get(query_url)
        let results = []
        for (let index in res.data.results) {
            results.push(new this(res.data.results[index]))
        }

        // add pagination data to the returned list
        Object.defineProperty(results, 'count', {
            value: res.data.count,
            enumerable: false,
            writable: false,
        })
        if (res.data.next) {
            Object.defineProperty(results, 'next', {
                value: page + 1,
                enumerable: false,
                writable: false,
            })
        }
        if (res.data.previous) {
            Object.defineProperty(results, 'previous', {
                value: page - 1,
                enumerable: false,
                writable: false,
            })
        }
        return results
    }

    static async create(params) {
        let res = await axios.post(this.namespace.join('/') + '/', params)
        return new this(res.data)
    }

    async sync() {
        let res = await axios.get(this.api_path)
        for (let property in res.data) {
            if (this.constructor.fields.indexOf(property) !== -1)
                this[property] = res.data[property]
        }
    }

    async save(update_fields = [], reload = true) {
        let update_data = {}
        if (update_fields.length === 0) update_fields = this.constructor.fields
        for (let field in update_fields) {
            update_data[update_fields[field]] = this[update_fields[field]]
        }

        try {
            let res = await axios.patch(this.api_path, update_data)
            // assign the changes to the local object
            for (let field in res.data) {
                this[field] = res.data[field]
            }
            return res.data
        } catch (err) {
            handle_error(err)
        }
    }

    async destroy() {
        try {
            return await axios.delete(this.api_path)
        } catch (err) {
            handle_error(err)
        }
    }
}

class Bounty extends BaseModel {
    static get namespace() {
        return ['bounties']
    }

    static get fields() {
        return BaseModel.fields.concat([
            'name',
            'description',
            'content',
            'deadline',
            'budget',
            'hide_budget',
            'location',
            'eligibility',
            'can_edit',
            'offered_solution',
            'offered_proposal',
            'owner',
            'approved',
        ])
    }

    async get_applications() {
        try {
            let res = await axios.get(this.api_path + 'applications/')
            let results = []
            for (let index in res.data.results) {
                results.push(new BountyApplication(res.data.results[index]))
            }
            return results
        } catch (e) {
            return []
        }
    }

    get client_path() {
        return '/bounty/' + this.object_id
    }
}

class BountyApplication extends BaseModel {
    static get namespace() {
        return ['bounties', 'applications']
    }

    static get fields() {
        return BaseModel.fields.concat(['owner', 'bounty', 'proposal_link'])
    }

    async accept() {
        try {
            await axios.post(this.api_path + 'accept/', {})
        } catch (e) {
            handle_error(e)
        }
    }
}

class BountyProposal extends BaseModel {
    static get namespace() {
        return ['bounties', 'proposals']
    }

    get client_path() {
        return '/proposal/' + this.object_id
    }

    static get fields() {
        return BaseModel.fields.concat([
            'owner',
            'bounty',
            'pending',
            'declined',
            'accepted',
            'content',
            'can_edit',
            'can_accept',
        ])
    }

    async post() {
        try {
            await axios.post(this.api_path + 'post/', {})
        } catch (e) {
            handle_error(e)
        }
    }

    async accept() {
        try {
            await axios.post(this.api_path + 'accept/', {})
        } catch (e) {
            handle_error(e)
        }
    }

    async decline() {
        try {
            await axios.post(this.api_path + 'decline/', {})
        } catch (e) {
            handle_error(e)
        }
    }
}

class CommunityInvitation extends BaseModel {
    static get namespace() {
        return ['communities', 'invitations']
    }

    static get fields() {
        return BaseModel.fields.concat([
            'community',
            'invite_code',
            'last_used',
            'times_used',
        ])
    }

    async invite_member(email) {
        try {
            await axios.post(this.api_path + 'invite/', {
                member: email,
            })
        } catch (e) {
            handle_error(e)
        }
    }
}

class Community extends BaseModel {
    static get namespace() {
        return ['communities']
    }

    static get fields() {
        return BaseModel.fields.concat([
            'name',
            'description',
            'logo',
            'banner',
            'slack_webhook',
            'can_edit',
        ])
    }

    get client_path() {
        return '/community/' + this.object_id
    }

    async invite_member(email) {
        try {
            await axios.post(this.api_path + 'members/', {
                member: email,
                action: 'invite',
            })
        } catch (e) {
            handle_error(e)
        }
    }

    async kick_member(email) {
        try {
            await axios.post(this.api_path + 'members/', {
                member: email,
                action: 'kick',
            })
        } catch (e) {
            handle_error(e)
        }
    }

    async generate_invitation() {
        let res = await axios.post(this.api_path + 'invitations/')
        return await this.get_invitations()
    }

    async get_invitations() {
        try {
            let res = await axios.get(this.api_path + 'invitations/')
            let results = []
            for (let index in res.data.results) {
                results.push(new CommunityInvitation(res.data.results[index]))
            }
            return results
        } catch (e) {
            return []
        }
    }

    async get_members(page = 1) {
        try {
            let res = await axios.get(this.api_path + 'members/', {
                page: page,
            })
            let results = []
            for (let index in res.data.results) {
                results.push(new User(res.data.results[index]))
            }
            return results
        } catch (e) {
            return []
        }
    }
}

class User extends BaseModel {
    static get fields() {
        return BaseModel.fields.concat([
            'first_name',
            'last_name',
            'email',
            'linkedin',
            'skills',
            'location',

            'community_manager',
            'community_members',

            'custom_flags',

            'company_name',
            'company_website',
            'company_description',
            'company_linkedin',
            'company_twitter',
            'company_stage',
            'company_location',
            'company_employee_count',
            'product_stage',
            'company_rd_spend',
            'company_blanked_requirements',
            'company_procurement_process',
            'company_risk_profile_acceptance',

            'solution_seeker',

            'is_profile_complete',
            'can_edit',
            'tags',

            'has_access',
            'is_staff',
        ])
    }

    static get namespace() {
        return ['users']
    }

    get client_path() {
        return '/users/' + this.object_id
    }

    static logout() {
        Auth.signOut()
        window.location.href = '/'
    }

    async set_access(access) {
        try {
            await axios.post(this.api_path + 'has_access/', {
                has_access: access,
            })
        } catch (e) {
            handle_error(e)
        }
    }

    async set_solution_seeker(solution_seeker) {
        try {
            await axios.post(this.api_path + 'solution_seeker/', {
                solution_seeker: solution_seeker,
            })
        } catch (e) {
            handle_error(e)
        }
    }
}

export {
    BaseModel,
    User,
    Community,
    Bounty,
    CommunityInvitation,
    BountyApplication,
    BountyProposal,
}
