import React from 'react'

import history from '../../contrib/history'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

import { Bounty } from '../../contrib/models'
import BountyList from '../../bounty/components/list'

import {
    Button,
    InputGroup,
    Intent,
    Card,
    NonIdealState,
    ButtonGroup,
} from '@blueprintjs/core'

import { Auth } from 'aws-amplify'

class Bounties extends React.Component {
    state = observable({
        models: [],
        page: 1,
        count: 0,
        previous: null,
        next: null,
    })

    load = async () => {
        await Auth.currentSession()
        let results = await Bounty.paginated_list(
            { recommended: true },
            this.state.page
        )

        this.state.models = results.results
        this.state.count = results.count
        this.state.next = results.next
        this.state.previous = results.previous

        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    model_updated() {
        this.load()
    }

    render_empty_state = () => {
        return (
            <Card>
                <NonIdealState
                    icon="search"
                    title="No Bounties!"
                    description="We couldn't find any bounties that fits your profile. Please check back later as we are always adding new Bounties."
                />
            </Card>
        )
    }

    render_pagination = () => {
        return (
            <div className="end-xs">
                <ButtonGroup>
                    <Button
                        disabled={this.state.previous == null}
                        onClick={() => {
                            this.state.page -= 1
                            this.load()
                        }}
                    >
                        Previous Page
                    </Button>
                    <Button>{this.state.count} Bounties Recommended</Button>
                    <Button
                        disabled={this.state.next == null}
                        onClick={() => {
                            this.state.page += 1
                            this.load()
                        }}
                    >
                        Next Page
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

    render() {
        var inner_render
        if (this.state.models.length === 0)
            inner_render = this.render_empty_state()
        else
            inner_render = [
                <BountyList items={this.state.models} />,
                this.render_pagination(),
            ]

        return (
            <div>
                <h3>Recommended Bounties For You</h3>
                {inner_render}
            </div>
        )
    }
}

export default observer(Bounties)
