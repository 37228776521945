import React from 'react'
import state from '../../contrib/state'
import {
    Navbar,
    Alignment,
    Button,
    Intent,
    EditableText,
    Callout,
    Position,
    Popover,
    Toaster,
    Card,
    Divider,
    Checkbox,
} from '@blueprintjs/core'

import { BountyProposal } from '../../contrib/models'
import Overview from '../../contrib/components/overview'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { handle_error } from '../../contrib/utils'

import Editor from 'rich-markdown-editor'
import history from '../../contrib/history'

class ProposalOverview extends Overview {
    ui = observable({})
    state = observable({
        temp_contnet: '',
        temp_content_changed: false,
    })
    model_class = BountyProposal

    save_model_attribute(attr) {
        return async (value) => {
            this.model()[attr] = value
            try {
                await this.model().save([attr])
                this.forceUpdate()
            } catch (e) {
                handle_error(e)
            }
        }
    }

    update_model_attribute(attr) {
        return (value) => {
            this.model()[attr] = value
            this.forceUpdate()
        }
    }

    render_stakeholder_decisions = () => {
        if (this.model().can_accept) {
            return [
                <Popover>
                    <Button intent={Intent.DANGER}>Decline Proposal</Button>
                    <div className="popover-small content">
                        This will discard this proposal. Are you sure?
                        <div className="tiny-gap" />
                        <div className="end-xs">
                            <Button
                                onClick={async () => {
                                    await this.model().decline()
                                    await this.model().sync()
                                    this.forceUpdate()
                                }}
                            >
                                Decline Proposal
                            </Button>
                        </div>
                    </div>
                </Popover>,
                <div className="horizontal-gap" />,
                <Popover>
                    <Button intent={Intent.SUCCESS}>Accept Proposal</Button>
                    <div className="popover-small content">
                        This will notify the innovator of a match. Are you sure?
                        <div className="tiny-gap" />
                        <div className="end-xs">
                            <Button
                                onClick={async () => {
                                    await this.model().accept()
                                    await this.model().sync()
                                    this.forceUpdate()
                                }}
                            >
                                Accept Proposal
                            </Button>
                        </div>
                    </div>
                </Popover>,
            ]
        }
    }

    render_result = () => {
        if (this.model().accepted) {
            return (
                <Callout intent={Intent.SUCCESS}>
                    Congratulations! This proposal was accepted. Please check
                    your email inbox for next steps!
                </Callout>
            )
        } else if (this.model().declined) {
            return (
                <Callout intent={Intent.WARNING}>
                    Unfortunately this proposal was declined!
                </Callout>
            )
        }
        return <div />
    }

    render_static = () => {
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                {this.model().owner.first_name}{' '}
                                {this.model().owner.last_name}'s Proposal
                            </Navbar.Heading>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            {this.render_stakeholder_decisions()}
                        </Navbar.Group>
                    </div>
                </Navbar>
                <div className="content-view bp3-text-large bp3-running-text">
                    <div className="tiny-gap" />
                    {this.render_result()}
                    <div className="small-gap" />
                    <Editor value={this.model().content} readOnly={true} />
                    <div className="tiny-gap" />
                </div>
            </div>
        )
    }

    render_editable = () => {
        return (
            <div>
                <Navbar>
                    <div className="content-view">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                {this.model().owner.first_name}{' '}
                                {this.model().owner.last_name}'s Proposal
                            </Navbar.Heading>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Popover>
                                <Button intent={Intent.SUCCESS}>
                                    Submit Proposal
                                </Button>
                                <div className="popover-small content">
                                    You can no longer a proposal after its
                                    submitted. Are you sure?
                                    <div className="tiny-gap" />
                                    <div className="end-xs">
                                        <Button
                                            onClick={async () => {
                                                await this.model().post()
                                                await this.model().sync()
                                                this.forceUpdate()
                                            }}
                                        >
                                            Submit Proposal
                                        </Button>
                                    </div>
                                </div>
                            </Popover>
                        </Navbar.Group>
                    </div>
                </Navbar>
                <div className="content-view bp3-text-large bp3-running-text">
                    <div className="small-gap" />
                    <Editor
                        value={this.model().content}
                        readOnly={false}
                        onChange={(v) => {
                            this.state.temp_content = v()
                            this.state.temp_content_changed = true
                        }}
                        onBlur={() => {
                            if (this.state.temp_content_changed) {
                                this.model().content = this.state.temp_content
                                this.model().save(['content'])
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    render_content = () => {
        if (this.model().can_edit) return this.render_editable()
        return this.render_static()
    }
}

export default observer(ProposalOverview)
