import React from 'react'

import { observable } from 'mobx'
import { observer } from 'mobx-react'

class Overview extends React.Component {
    render_static = () => {
        return <div>test</div>
    }

    render_editable = () => {
        return <div />
    }

    render() {
        if (this.props.model.can_edit) return this.render_editable()
        return this.render_static()
    }
}

export default observer(Overview)
